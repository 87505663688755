import Vue from 'vue'

export default class APIs {
    /**
     * 取得分會場資訊
     */
    async getVenueInfo (_success, _error) {
        await Vue.http.get('exhibition/venue').then(_success, _error)
    }
}
