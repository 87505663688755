<template>
  <fragment>
    <slot />
  </fragment>
</template>

<script>
import Vue from 'vue'
import { Fragment } from 'vue-fragment'

export default Vue.extend({
  components: {
    Fragment,
  },
  updated() {
    this.$root.checkoutIfIsInTime()
    this.$root.updateDocumentTitle()
  },
  created () {
    this.$root.checkoutIfIsInTime()
    this.$root.updateDocumentTitle()
  },
})
</script>
