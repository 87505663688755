import Vue from 'vue'
import VueRouter from 'vue-router'
import Lobby from '@/views/Lobby.vue'
import Login from '@/views/Login.vue'
import Lecture from '@/views/Lecture.vue'
import Area from '@/views/Area.vue'
import Venue from '@/views/Venue.vue'
import QuickAccess from '@/views/QuickAccess.vue'
import Logout from '@/views/Logout.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import NotInTime from '@/views/NotInTime.vue'
import After from '@/views/After.vue'
import Before from '@/views/Before.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/after',
    name: 'After',
    component: After,
    meta: {
      title: '已結束',
      description: ''
    }
  },
  {
    path: '/before',
    name: 'Before',
    component: Before,
    meta: {
      title: '未開始',
      description: ''
    }
  },
  {
    path: '/index',
    name: 'Index',
    component: Lobby,
    meta: {
      title: '大廳',
      description: ''
    }
  },
  // --- 主演講廳
  {
    path: '/lecture',
    name: 'Lecture',
    component: Lecture,
    meta: {
      title: '主演講廳',
      description: ''
    }
  },
  // --- 攤位區
  {
    path: '/area',
    name: 'Area',
    component: Area,
    meta: {
      title: '攤位區',
      description: ''
    }
  },
  // --- 分會場
  {
    path: '/venue',
    name: 'Venue',
    component: Venue,
    meta: {
      title: '分會場',
      description: ''
    }
  },
  // --- 登入
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '活動登入',
      description: '請輸入帳號密碼'
    }
  },
  // --- 快速登入
  {
    path: '/quickAccess/:id',
    name: 'QuickAccess',
    component: QuickAccess,
    meta: {
      title: '快速登入',
    }
  },
  // --- 登出
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      title: '登出',
    }
  },
  // --- 重設密碼
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: '重設密碼',
    }
  },
  {
    path: '/reset-password/',
    redirect: '/login',
  },
  {
    path: '/not-in-time',
    name: 'NotInTime',
    component: NotInTime,
    meta: {
      title: '非活動期間',
    }
  },
  {
    path: '/404',
    name: '404 Not Found',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'Oops! 你輸入的網址不存在',
      description: 'Oops! 你輸入的網址不存在'
    }
  },
  {
    path: '*',
    redirect: '/404',
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.description) {
    document.description = to.meta.description
  }

  // 若無網路 則跳小恐龍
  if (!navigator.onLine) {
    window.location.reload(); 
  }
  next()
})

export default router
