<template>
  <div class="lobby-greeting">
    <div class="lobby-greeting-kv">
      <embed-frame>
        <v-img :src="data.image" />
      </embed-frame>
    </div>
    <div class="lobby-greeting-dialog">
      <span>{{ data.wording }}</span>
    </div>
  </div>
</template>

<style lang="sass" scoped>
  .lobby-greeting
    bottom: 0
    display: flex
    margin: auto
    max-width: 400px
    padding: 3em 1em 0
    pointer-events: none

    @media screen and (min-width: 768px)
      bottom: 0
      left: 1vw
      max-width: none
      position: absolute
      width: 25vw
      z-index: 1000

    &-kv
      flex-basis: 50%

    &-dialog
      align-items: center
      background: rgba(255, 255, 255, .7)
      border-radius: 10px
      display: flex
      flex: 1
      justify-content: center
      margin: -2em 0 2em
      padding: 1em
      position: relative
      width: 50%

      @media screen and (min-width: 768px)
        font-size: 1vw
        margin-bottom: 1em
        margin-top: 0

      &:before
        background-image: url('/lobby/greeting-dialog-arrow.svg')
        background-position: right center
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: 20px
        left: -21px
        opacity: .7
        position: absolute
        width: 21px

      span
        -webkit-box-orient: vertical
        -webkit-line-clamp: 3
        display: -webkit-box
        max-width: 100%
        overflow: hidden
        text-align: left
        white-space: pre
</style>

<script lang="ts">
import EmbedFrame from '@/components/shared/EmbedFrame.vue'
import Vue from 'vue'
export default Vue.extend({
  components: {
    EmbedFrame,
  },
  props: {
    data: Object,
  }
})
</script>
