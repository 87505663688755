import Vue from 'vue'

export default class APIs {
    /**
     * 取得展會資訊
     */
    async getExhibitionInfo (_success, _error) {
        await Vue.http.get('exhibition').then(_success, _error)
    }

    /**
     * 取得頁籤資訊
     * @param {*} _success 
     * @param {*} _error 
     */
    async getExhibitionTabInfo (_success, _error) {
        await Vue.http.get('exhibition/tab').then(_success, _error)
    }

    /**
     * 登入資訊
     * @param {*} 
     */
     async getExhibitionLoginInfo (_success, _error) {
        await Vue.http.get('exhibition/login').then(_success, _error)
    }

    /**
     * 行為紀錄
     * @param {*} 
     */
     async createEvent (_parameter ,_success, _error) {
        await Vue.http.put('exhibition/event',_parameter).then(_success, _error)
    }

    /**
     * 已登入 未登入畫面資訊
     * @param {*} 
     */
     async getExhibitionClose (_success, _error) {
        await Vue.http.get('exhibition/close').then(_success, _error)
    }
}