import Vue from 'vue'
import { getStoredRefreshToken } from '@/utils/auth'

export default class APIs {
    /**
     * 登入
     * @param {*} username
     * @param {*} password
     */
    async loginAccount (_username = false, _password = false, _success, _error) {
        await Vue.http.post('account/login', {
            username: _username,
            password: _password
        }).then(_success, _error)
    }

    /**
     * 快速登入
     * @param {*} code
     */
    async QuickAccess (_code = false, _success, _error) {
        await Vue.http.get('account/passport?code=' + _code).then(_success, _error)
    }

    /**
     * refresh Token
     * @param {*} refreshToken
     */
    async reFreshToken (_success, _error) {
        await Vue.http.post('account/refresh-token', {
            refreshToken: getStoredRefreshToken(),
        }).then(_success, _error)
    }

    /**
     * 登出
     * @param {*} 
     */
    async logoutAccount (_success, _error) {
        await Vue.http.get('account/logout').then(_success, _error)
    }

    /**
     * 要求重設密碼 Token
     * @param {*} 
     */
    async getResetAccountToken (_email, _success, _error) {
        await Vue.http.post('account/request-reset', {
            email: _email
        }).then(_success, _error)
    }

    /**
     * 確認重設密碼 Token
     * @param {*} 
     */
    async checkResetAccountToken (_token = false, _success, _error) {
        await Vue.http.get('account/reset?token=' + _token).then(_success, _error)
    }

    /**
     * 重設密碼
     * @param {*} 
     */
    async resetAccount (_token = false, _password = false, _success, _error) {
        await Vue.http.post('account/reset?token=' + _token, {
            password: _password
        }).then(_success, _error)
    }
}
