<template>
  <div
    class="lecture"
    v-if="lecture_data.lecture_bg"
    :style="`background-image: url('${lecture_data.lecture_bg}')`"
    :class="!lecture_data.lecture_isStart ? 'no-scroll' : ''"
  >
    <lecture-top
      :top_data="lecture_data.top"
      :now_marquee_msg="now_marquee_msg"
      :now_msg_num="now_msg_num"
      v-on:changeMsg="changeMsg()"
    />
    <lecture-middle :data="lecture_data.middle" />
    <lecture-bottom :data="lecture_data.bottom" />

    <lecture-unstart
      v-if="!lecture_data.lecture_isStart"
      :data="lecture_data.unstart"
    />
  </div>
</template>

<style lang="sass" scoped>
.lecture
  position: relative
  background-repeat: no-repeat
  background-position: bottom center
  background-size: cover
  min-height: 91vh
  overflow-x: hidden
  @media screen and (min-width: 768px)
    background-size: cover
    min-height: 100%
.no-scroll
  height: 91vh
  overflow-y: hidden
</style>

<script>
import Vue from "vue";
import LectureTop from "@/components/pages/lecture/LectureTop.vue";
import LectureBottom from "@/components/pages/lecture/LectureBottom.vue";
import LectureMiddle from "@/components/pages/lecture/LectureMiddle.vue";
import LectureUnstart from "@/components/pages/lecture/LectureUnstart.vue";
import APIs from "@/api/lecture.js";
import exhibitionAPIs from "@/api/exhibition.js";

export default Vue.extend({
  components: {
    LectureTop,
    LectureMiddle,
    LectureBottom,
    LectureUnstart,
  },
  data: function () {
    return {
      apis: false,
      exhibitionAPIs: false,
      // 使用區
      lecture_data: {},

      //- 暫存區
      storage_area: false,

      //- 5分鐘 時間倒數 暫時修改為 60 秒做測試
      frequency: 60,
      timer: null,
      time: this.frequency,

      //- 跑馬燈相關
      now_marquee_msg: {},
      total_msg_num: 0,
      now_msg_num: 0,
      now_second: Math.round(new Date().getTime() / 1000)
    };
  },
  async created() {
    this.apis = await new APIs(this);
    this.exhibitionAPIs = await new exhibitionAPIs(this);

    // 呼叫登入 API
    // 取得演講廳資訊
    await this.getLectureInfo();

    // 紀錄行為 觀看影片
    this.createLectureEvent();
  },
  mounted() {
    // const vm = this
    this.timer = setInterval(this.countdown, 1000);

    // 監聽離開時 紀錄停留該頁面時間
    // vm.$router.beforeEach((to, from, next) => {
    //   vm.createLectureTimeEvent()
    //   next();
    // })
  },
  beforeDestroy() {
    this.time = 0;
    clearInterval(this.timer);
  },
  // INFO: for timer debug 用
  watch: {
    time: function (value) {
      console.log(
        `%c擷取最新資訊倒數${value}秒`,
        "background: #222; color: #ffff00; padding: 1px 3px;"
      );
    },
  },
  methods: {
    createLectureTimeEvent() {
      const vm = this
      let time = 60

      // 因為目前無法取得單一影片的時間 所以name 一律留空
      const parameter = {
        event: "lecture.watch",
        data: {
          "name": vm.lecture_data.middle.screen.name,
          "play_time": time
        },
      };
      
      this.exhibitionAPIs.createEvent(
        parameter,
        this.createEventTimeSuccess,
        this.createEventTimeError
      );
    },
    createLectureEvent() {
      const vm = this

      // 因為目前無法取得單一影片的時間 所以name 一律留空
      const parameter = {
        event: "lecture.play",
        data: {
          name: vm.lecture_data.middle.screen.name,
        },
      };
      this.exhibitionAPIs.createEvent(
        parameter,
        this.createEventSuccess,
        this.createEventError
      );
    },
    createEventTimeSuccess(_response){
      if (_response.status === 200) {
        console.log("成功紀錄演講廳觀看時間");
      }
      this.now_second = Math.round(new Date().getTime() / 1000)
    },
    createEventTimeError(_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    createEventSuccess(_response) {
      if (_response.status === 200) {
        console.log("成功紀錄演講廳進入");
      }
    },
    createEventError(_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    //時間倒數
    async countdown() {
      this.time--;
      if (this.time == 1) {
        // 如果該時段有影片 紀錄觀看時間
        if(this.lecture_data.middle.screen.name){
          await this.createLectureTimeEvent()
        }
        // 若有節目則紀錄
        await this.getLectureInfo();
      }
      // console.log('====== ' + this.time + ' =======')
    },
    giveMarqueeData() {
      this.total_msg_num = this.lecture_data.top.marquee.marquee_text.length;
      this.now_marquee_msg = {'value': this.lecture_data.top.marquee.marquee_text[0].text};
      console.log("共有 " + this.total_msg_num + " 則跑馬燈訊息");
      console.log("目前播放的是第 1 則跑馬燈訊息");
    },
    changeMsg() {
      //- 如果目前播放的則數比總則數小就播下一則
      if (this.now_msg_num < this.total_msg_num) {
        this.now_msg_num = this.now_msg_num + 1;
      }
      //- 如果目前播放的則數等於總則數就重播第一則
      if (this.now_msg_num === this.total_msg_num) {
        this.now_msg_num = 0;
      }
      console.log("共有 " + this.total_msg_num + " 則跑馬燈訊息");
      console.log("目前播放的是第 " + (this.now_msg_num + 1) + " 則跑馬燈訊息");
      
      this.now_marquee_msg =
        {'value': this.lecture_data.top.marquee.marquee_text[this.now_msg_num].text};
    },
    // 取得演講廳資訊
    getLectureInfo() {
      this.apis.getLectureInfo(this.getLectureSuccess, this.getLectureError);
    },
    // 取資料處理回傳正常的回應
    getLectureSuccess(_response) {
      if (_response.status === 200) {
        console.log("成功取得演講廳資訊");
        //- 如果暫區為空
        if (!this.storage_area) {
          this.lecture_data = _response.body;
          this.storage_area = _response.body;
        } else {
          console.log("=== 有內容不一樣 ===");
          //-比對背景
          var bg_same = this.comparisonData(
            this.storage_area.lecture_bg,
            _response.body.lecture_bg
          );
          if (!bg_same) {
            this.lecture_data.lecture_bg = _response.body.lecture_bg;
            this.storage_area.lecture_bg = _response.body.lecture_bg;
            console.log("=== 背景不一樣 已替換 ===");
          }
          // 議程是否開始狀態
          var isStart_same = this.comparisonData(
            this.storage_area.lecture_isStart,
            _response.body.lecture_isStart
          );
          if (!isStart_same) {
            this.lecture_data.lecture_isStart = _response.body.lecture_isStart;
            this.storage_area.lecture_isStart = _response.body.lecture_isStart;
            console.log("=== 開始狀態不一樣 已替換 ===");
          }
          //-比對頂部
          var top_same = this.comparisonData(
            this.storage_area.top,
            _response.body.top
          );
          if (!top_same) {
            this.lecture_data.top = _response.body.top;
            this.storage_area.top = _response.body.top;
            console.log("=== 跑馬燈不一樣 已替換 ===");
          }
          //-比對中段
          var middle_same = this.comparisonData(
            this.storage_area.middle,
            _response.body.middle
          );
          if (!middle_same) {
            this.lecture_data.middle = _response.body.middle;
            this.storage_area.middle = _response.body.middle;
            console.log("=== 中段不一樣 已替換 ===");
          }
          //-比對下方
          var bottom_same = this.comparisonData(
            this.storage_area.bottom,
            _response.body.bottom
          );
          if (!bottom_same) {
            this.lecture_data.bottom = _response.body.bottom;
            this.storage_area.bottom = _response.body.bottom;
            console.log("=== 下方不一樣 已替換 ===");
          }
          //-比對議題尚未開始內容
          var unstart_same = this.comparisonData(
            this.storage_area.unstart,
            _response.body.unstart
          );
          if (!unstart_same) {
            this.lecture_data.unstart = _response.body.unstart;
            this.storage_area.unstart = _response.body.unstart;
            console.log("=== 議題尚未開始內容不一樣 已替換 ===");
          }
        }
        this.giveMarqueeData();
        this.time = this.frequency;
      }
    },
    // 取資料登入處理回傳異常的回應
    getLectureError(_response) {
      this.time = 0;
      clearInterval(this.timer);
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    //- 比對方法
    comparisonData(a_data, b_data) {
      let a = JSON.stringify(a_data);
      let b = JSON.stringify(b_data);
      if (a === b) {
        return true;
      } else {
        return false;
      }
    },
  },
});
</script>
