<template>
  <layout>
    <v-app>
      <v-main hide-details>
        <div id="app">
          <template v-if="$route.name == 'Login'">
            <v-alert
              class="alert"
              :color="$root.alert_color"
              v-if="$root.alert_message"
            >
              {{ $root.alert_message }}
            </v-alert>
          </template>
          <navbar v-if="!isLoginResetPage" />
          <div id="app-content" :class="isLoginResetPage ? 'no-nav' : null">
            <router-view />
          </div>
        </div>
      </v-main>
    </v-app>
  </layout>
</template>

<style lang="sass">
#app
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  background: #fefefe
  color: #2c3e50
  font-family: 'Noto Sans TC', Helvetica, Arial, sans-serif
  min-height: 100vh
  text-align: center

  .alert
    color: #fff
    position: absolute
    right: 1rem
    top: 1rem
    width: fit-content
    z-index: 101

  #app-content
    min-height: calc(100vh - 66px)
    margin-bottom: 66px
    position: relative

    @media screen and (min-width: 768px)
      height: calc(100vw * 8.05 / 16)
      margin-bottom: 0
      min-height: auto

    &.no-nav
      height: 100vh
      margin-bottom: 0
  #nav
    padding: 30px

    a
      color: #2c3e50
      font-weight: bold

      &.router-link-exact-active
        color: #42b983

  .v-main
    overflow: auto

  img
    max-width: 100%
    vertical-align: middle
</style>

<script lang="ts">
import Vue from "vue";
import Navbar from "@/components/shared/Navbar.vue";
import Layout from "@/components/shared/Layout.vue";
import APIs from "@/api/exhibition.js";

export default Vue.extend({
  components: {
    Layout,
    Navbar,
  },
  data: function () {
    return {
      apis: false,
      exhibition_asset_data: Object,
      FBMessageSrc:
        "/api/exhibition/asset",
    };
  },
  methods: {
    createEventSuccess(_response) {
      if (_response.status === 200) {
        console.log("成功紀錄上線行為");
      }
    },
    createEventError(_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    }
  },
  mounted() {
    const vm = this;
    // 加入fb message 機器人script腳本
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", vm.FBMessageSrc);
    document.head.appendChild(recaptchaScript);
  },
  computed: {
    // 判斷是否為 Login 頁，用於關閉導覽列表
    isLoginResetPage() {
      return (
        this.$route.name === "NotInTime" ||
        this.$route.name === "Login" ||
        this.$route.name === "ResetPassword" ||
        this.$route.name === "After" ||
        this.$route.name === "Before" ||
        this.$route.name === "Logout"
      );
    },
  },
  async created() {
    this.apis = await new APIs(this);
    await this.apis.createEvent(
      { event: "online", data: {} },
      this.createEventSuccess,
      this.createEventError
    );
  },
});
</script>

<style>
video:-webkit-full-screen {
   width: 100%;
   height: 100%;
   max-height: 100%;
}
</style>