<template>
  <div class="top-banner">
    <div
      class="top-banner-image"
      :style="`background-image: url('${src}')`"
    ></div>
  </div>
</template>


<style lang="sass" scoped>
  .top-banner
    margin: auto
    width: 100%

    @media screen and (min-width: 768px)
      max-width: 40%

    @media screen and (min-width: 1200px)
      max-width: 35%

    &-image
      padding-bottom: calc(100% * 720 / 2430 )
      background-size: cover
      background-position: center
</style>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    src: String
  }
})
</script>
