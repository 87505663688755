<template>
  <div class="area-item">
    <!-- 展覽圖片 -->
    <div
      class="item-bg"
      :style="`background-image: url('${data.stall_img}')`"
    ></div>

    <!-- 展覽敘述文字 -->
    <a class="item-description" :href="data.url ? data.url : 'javascript:void(0)'" :target="data.url ? '_blank' : ''">
      <div class="description-name"> {{data.name}} </div>
      <div class="description-text"> {{data.text}} </div>
    </a>
  </div>
</template>
<style lang="sass" scoped>
.area-item
  width: 25%
  height: 88%
  position: relative
  @media (min-width: 1500px)
    margin-top: .8rem
    height: 95%
  &:hover .item-description
    opacity: 1
    display: block
    text-decoration: none
  .item-bg
    background-repeat: no-repeat
    background-position: bottom
    background-size: contain
    width: 200px
    height: calc(180px /3 *2)
    position: absolute
    bottom: 0
    transition: .3s
    left: calc((100% - 180px)/2)
    @media (min-width: 860px)
      width: 220px
      height: calc(220px /3 *2)
      left: calc((100% - 220px)/2)
    @media (min-width: 1010px)
      width: 250px
      height: calc(250px /3 *2)
      left: calc((100% - 250px)/2)
    @media (min-width: 1200px)
      width: 300px
      height: calc(300px /3 *2)
      left: calc((100% - 300px)/2)
    @media (min-width: 1500px)
      width: 350px
      height: calc(350px /3 *2)
      left: calc((100% - 350px)/2)
    @media (min-width: 1700px)
      width: 400px
      height: calc(400px /3 *2)
      left: calc((100% - 400px)/2)

  .item-description
    background: rgba(0,0,0,.65)
    border-radius: 7px
    box-sizing: border-box
    color: #fff
    height: 100%
    left: 0
    opacity: 0
    overflow: auto
    padding: 1rem
    position: absolute
    text-decoration: none
    top: 0
    transition: .5s
    width: 100%
    .description-name
      font-size: 1.2rem
      letter-spacing: .5px
    .description-text
      margin-top: .8rem
      font-size: .85rem
      text-align: left
</style>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  components: {},
  props: {
    data: Object,
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
  },
});
</script>
