import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import swiper from './plugins/swiper'
import splide from './plugins/splide'
import store from './store'
import router from './router'
import APIs from '@/api/account.js'
import exhibitionAPIs from '@/api/exhibition.js'
import VueLocalStorage from 'vue-localstorage'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
import { getStoredAccessToken, setStoredAccessToken, setStoredRefreshToken } from '@/utils/auth'

Vue.use(VueLocalStorage)
Vue.use(VueResource)
Vue.use(VueCookies)

Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push(function () {
    let _authToken = 'Bearer ' + getStoredAccessToken()
    Vue.http.headers.common['Authorization'] = _authToken
    Vue.http.headers.common['Content-Type'] = 'application/json'
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  swiper,
  store,
  router,
  splide,
  render: h => h(App),
  data: {
    apis: false,
    exhibition_apis: false,
    exhibition_data: {},
    alert_color: '',
    alert_message: '',
    isLogin: false,
    isInTime: false,
    splitCharacter: '｜',
    time: {
      now: null,
      start_at: null,
      end_at: null
    }
  },
  async created () {
    window.addEventListener("resize", this.detectResize)
    this.apis = await new APIs(this)
    this.exhibition_apis = await new exhibitionAPIs(this)
    this.isInTime = false

    await this.exhibition_apis.getExhibitionInfo(this.getExhibitionSuccess, this.getExhibitionError)

    this.time.now = new Date().getTime()
    this.time.start_at = new Date(this.exhibition_data.start_at).getTime()
    this.time.end_at = new Date(this.exhibition_data.end_at).getTime()
    this.checkoutIfIsInTime()
    this.updateDocumentTitle()
  },
  methods: {
    // 更新網頁標題
    updateDocumentTitle () {
      // document.title = document.title.split(this.$root.splitCharacter)[0] + this.$root.splitCharacter + this.$root.exhibition_data.name
      document.title = this.$root.exhibition_data.name
    },
    //- 寫入 Storage
    setStorage (_index, _value) {
      Vue.localStorage.set(_index, JSON.stringify(_value))
    },
    //- 獲得 Storage
    getStorage (_index) {
      return JSON.parse(Vue.localStorage.get(_index))
    },
    // 檢測是否現在時間在活動區間
    checkoutIfIsInTime () {
      if (this.time.now < this.time.start_at || this.time.now > this.time.end_at) {
        this.isInTime = false
        this.redirectToNotInTime()
      } else {
        this.isInTime = true
      }
    },
    redirectToNotInTime () {
      // if (this.isLogin) {

        // 跳轉 router 接 catch 避免 Uncaught (in promise)
        // this.$router.push("/not-in-time").catch(()=>{})
        this.$router.push("/not-in-time").catch(()=>{})

      // }
    },
    //- 取得 refresh token
    async getRefreshToken () {
      console.log('refreshToken')
      await this.apis.reFreshToken(this.getRefreshTokenSuccess, this.getRefreshTokenError)
    },
    // 取refresh token處理回傳正常的回應
    getRefreshTokenSuccess (_response) {
      if (_response.status === 200) {
        console.log('成功取得新 token')
        setStoredAccessToken(_response.body.accessToken)
        setStoredRefreshToken(_response.body.refreshToken)
        this.$router.go()
      }
    },
    // 取refresh token處理回傳異常的回應
    getRefreshTokenError (_response) {
      console.error('未取得新 token')
      if (_response.status === 500) {
        this.useAlert("red", _response.body.msg)
        this.$router.push({ path: "/login" }).catch(()=>{})
      }
    },
    // 取資料處理回傳正常的回應
    getExhibitionSuccess (_response) {
      if (_response.status === 200) {
        this.exhibition_data = _response.body
        console.log('成功取得展覽資訊')
      }
    },
    // 取資料處理回傳異常的回應
    getExhibitionError (_response) {
      this.useAlert("red", _response.body.msg);
      if (_response.status === 401) {
        this.getRefreshToken()
      } else {
        this.useAlert("red", _response.body.msg);
      }
    },
    handleOnError (_response) {
      this.useAlert("red", _response.body.msg)
    },
    //- 使用Alert
    useAlert (_color, _message) {
      this.alert_color = _color
      this.alert_message = _message
      setTimeout(() => {
        this.alert_color = ''
        this.alert_message = ''
      }, 1500);
    }
  }
}).$mount('#app')
