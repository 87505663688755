<template>
  <div class="theme-content">
    <div class="theme-title">{{ data.theme_title }}</div>
    <div class="video-group">
      <div
        class="video-item"
        v-for="(video, index) in data.video_data"
        :key="index"
      >
        <!-- 影片小 banner -->
        <div
          class="video-banner"
          :style="`background-image: url('${video.video_banner}')`"
        ></div>

        <!-- 播放按鈕及遮罩 -->
        <div
          class="play-img"
          :style="`background-image: url('${play_img}')`"
          @click.stop="openScreen(video); setWatchTime(true, data.theme_title, video.video_title);video.point_status = true"
        ></div>

        <!-- 影片標題 -->
        <div class="video-title">{{ video.video_title }}</div>

        <!-- 標記區塊 -->
        <div class="point-box" v-if="point_show">
          <div
            class="point-img"
            :style="`background-image: url('${
              video.point_status ? video.point_img_on : video.point_img_off
            }')`"
          ></div>
          <div class="clearfix"></div>
        </div>

        <!-- 展覽敘述文字 -->
        <div
          class="item-description"
          @click.stop="
            openScreen(video);
            createVenueEvent(data.theme_title, video.video_title);
            setWatchTime(true, data.theme_title, video.video_title);
            video.point_status = true
          "
        >
          <div class="description-text">{{ video.video_description }}</div>
          <div class="play-btn">
            <div
              class="play-img-sm"
              :style="`background-image: url('${play_img}')`"
            ></div>
            <div class="play-text">{{ play_text }}</div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>
<style lang="sass" scoped>
.theme-content
  width: 95%
  margin: 0 auto
  padding-top: 2rem
  @media screen and (min-width: 500px)
    width: 90%

  .theme-title
    width: fit-content
    font-size: 1rem
    color: #fff
    text-align: center
    padding: .5rem 1rem
    background: rgba(0,0,0,.5)
    margin: 0 auto
    @media screen and (min-width: 768px)
      text-align: left
      margin-left: 1%
  .video-group
    width: 100%
    display: flex
    flex-wrap: wrap
    .video-item
      margin: 1rem 1% 1rem 1%
      background: #fff
      width: 48%
      max-width: 48%
      align-items: center
      flex-shrink: 0
      flex-grow: 1
      position: relative
      display: flex
      flex-direction: column
      // padding-bottom: 3.2rem
      &:hover .item-description
        opacity: 1

      @media screen and (min-width: 768px)
        width: 31%
        max-width: 31%
      @media screen and (min-width: 1300px)
        width: 23%
        max-width: 23%
    .video-banner
      width: 100%
      padding-bottom: calc(100% / 408 *233)
      background-repeat: no-repeat
      background-position: top center
      background-size: contain
    .play-img
      width: 100%
      padding-bottom: calc(100% / 408 *233)
      position: absolute
      left: 0
      top: 0
      background: rgba(0,0,0,.4)
      background-repeat: no-repeat
      background-position: center
      background-size: 20%
      z-index: 2
      display: block
      cursor: pointer

      @media screen and (min-width: 768px)
        display: none

    .video-title
      color: #19478A
      padding: .8rem .5rem
      flex-grow: 1
      @media screen and (min-width: 768px)
        padding: .9rem
    .point-box
      height: 2.4rem
      width: 100%
      position: relative
      background: linear-gradient(270deg, #E9E8E8 -0.87%, rgba(247, 247, 247, 0) 100%)
      @media screen and (min-width: 600px)
        height: 3.2rem
      .point-img
        float: right
        background-repeat: no-repeat
        background-position: center
        background-size: 1.8rem
        width: 2.4rem
        height: 2.4rem
        @media screen and (min-width: 600px)
          width: 3.5rem
          height: 3.5rem
          background-size: 2.8rem
    .item-description
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      background: rgba(0,0,0,.65)
      color: #fff
      padding: 1.5rem 2rem
      opacity: 0
      transition: .5s
      display: none
      cursor: pointer
      @media screen and (min-width: 768px)
        opacity: 0
        display: block
      .description-text
        height: calc(100% - 2rem)
        margin-top: .8rem
        font-size: .85rem
        text-align: left
        overflow: auto
      .play-btn
        position: absolute
        right: .8rem
        bottom: .5rem
        font-size: .9rem
        display: flex

        .play-img-sm
          background-repeat: no-repeat
          background-position: center
          background-size: 50%
          width: 2rem
          height: 1.35rem
    .clearfix
      clear: both
</style>

<script lang="ts">
import Vue from "vue";
import APIs from "@/api/exhibition.js";

export default Vue.extend({
  components: {},
  props: {
    data: Object,
    point_show: Boolean,
    play_img: String,
    play_text: String,
  },
  computed: {},
  data() {
    return {};
  },
  async created() {
    this.apis = await new APIs(this);
  },
  methods: {
    setWatchTime(str_end, name, cate) {
      this.$emit("setWatchTime", str_end, name, cate);
    },
    createVenueEvent(cata, name) {
      const parameter = {
        event: "venue.play",
        data: {
          cate: cata,
          name: name,
        },
      };
      this.apis.createEvent(
        parameter,
        this.createEventSuccess,
        this.createEventError
      );
    },
    createEventSuccess(_response) {
      if (_response.status === 200) {
        console.log("成功紀錄分會場影片點擊");
      }
    },
    createEventError(_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    openScreen(_url = "") {
      this.$emit("openScreen", _url);
    },
  },
});
</script>
