var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.slider_data.length !== 0)?_c('div',{staticClass:"lobby-slider"},[_c('splide',{attrs:{"options":_vm.splideOption}},_vm._l((_vm.slider_data),function(item,index){return _c('splide-slide',{key:item.name},[_c('embed-frame',[(item.type === 'image')?_c('div',{staticClass:"url-box"},[(item.url)?_c('a',{attrs:{"href":item.url,"target":item.external === true ? '_blank' : null}},[_c('v-img',{attrs:{"src":item.poster}})],1):_c('v-img',{attrs:{"src":item.poster}})],1):_vm._e(),(
            item.type === 'video' &&
            _vm.swiperCurrent.index === index &&
            _vm.swiperCurrent.isPlaying === true
          )?_c('video',{attrs:{"poster":item.poster,"src":item.url,"autoplay":"","controls":"","muted":"","playsinline":""},domProps:{"muted":true}}):_vm._e(),(
            item.type === 'iframe' &&
            _vm.swiperCurrent.index === index &&
            _vm.swiperCurrent.isPlaying === true
          )?_c('iframe',{attrs:{"src":item.url,"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e(),(
            item.type !== 'image' &&
            _vm.swiperCurrent.isPlaying === false
          )?_c('v-img',{attrs:{"src":item.poster},on:{"click":_vm.play}}):_vm._e()],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }