<template>
  <router-link to="/">
    <div class="lecture-unstart">
      <div class="unstart-main">
        <div
          v-if="data.mask_bg"
          class="unstart-content"
          :style="`background-image: url('${data.mask_bg}')`"
        >
          <div class="unstart-mask">
            <div
              v-if="data.mask_text"
              class="mask-text"
            > {{data.mask_text}} </div>
          </div>
        </div>
        <div
          v-if="data.back_text"
          class="back-text"
        > {{data.back_text}} </div>
      </div>
    </div>
  </router-link>
</template>

<style lang="sass" scoped>
.lecture-unstart
  position: fixed
  bottom: 0rem
  left: 0
  width: 100%
  background: rgba(255,255,255,.7)
  height: 100%
  z-index: 5
  .unstart-main
    margin-top: 0
    overflow: hidden
    position: absolute
    top: 50%
    transform: translateY(-50%)
    width: 100%
  .unstart-content
    width: 100%
    margin: 0 auto
    position: relative
    z-index: 1
    background-position: top
    background-repeat: no-repeat
    background-size: 100%
    bottom: 0
    left: 0
    right: 0
    top: 0
    padding-bottom: calc(100% * 9 / 16 )
    @media (min-width: 1000px)
      width: 64%
      padding-bottom: calc(64% * 9 / 16 )
    .unstart-mask
      position: absolute
      left: 0
      top: 0
      height: 100%
      width: 100%
      padding-top: 12%
      z-index: 2
      background: rgba(0,0,0,.7)
      .mask-text
        font-size: 1.5em
        color: #fff
        @media screen and (min-width: 400px)
          font-size: 2em
        @media screen and (min-width: 768px)
          font-size: 3em
        @media screen and (min-width: 1600px)
          font-size: 4em
  .back-text
    margin-top: 1rem
    font-size: 1.6em
    color: #3A5B8F
    line-height: 3rem
    font-size: 1.3em
    @media screen and (min-width: 400px)
      font-size: 1.5em
    @media screen and (min-width: 768px)
      line-height: 4rem
      font-size: 2.6em
</style>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    data: Object,
  },
});
</script>
