<template>
  <div class="area-small-content">
    <a
      class="small-item"
      v-for="(item,index) in data"
      :key="index"
      :href="item.url"
      target="_blank"
    >
      <div
        class="small-item-img"
        :style="`background-image: url('${item.logo}')`"
      ></div>
      <div class="small-item-name">
        {{item.name}}
      </div>

    </a>
  </div>
</template>
<style lang="sass" scoped>
.area-small-content
  padding: 2.5rem 25px 3rem
  width: 100%
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  column-gap: 9px
  row-gap: 30px

  @media screen and (min-width: 768px)
    display: none
  .small-item
    color: #000000
    cursor: pointer
    display: block
    text-decoration: none
    &:hover
      opacity: .8
    @media screen and (min-width: 450px)
      padding: 0 7.333%
    .small-item-img
      width: 100%
      padding-bottom: 100%
      background-repeat: no-repeat
      background-position: bottom
      background-size: contain
    .small-item-name
      font-size: 1rem
      color: #19478A
      margin-top: .4rem
      -webkit-box-orient: vertical
      -webkit-line-clamp: 2
      display: -webkit-box
      overflow: hidden
  .clearfix
    clear: both
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    data: Array,
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
});
</script>
