<template>
  <div class="logout">
    <p>即將為您登出</p>
    <loader color="#ffffff" />
  </div>
</template>

<style lang="sass" scoped>
.logout
  align-items: center
  background: linear-gradient(180deg, #EDF5FB 0%, #B9DFF2 50.48%, #ABCBE1 100%)
  display: flex
  flex-direction: column
  font-size: 1.5em
  font-weight: bold
  height: 100%
  justify-content: center
  overflow-x: hidden
  overflow-y: auto
  width: 100%
</style>

<script>
import Vue from 'vue'
import APIs from "@/api/account.js"
import {
  getStoredAccessToken,
  removeStoredAccessToken,
  removeStoredRefreshToken
} from '@/utils/auth'
import Loader from '@/components/shared/Loader.vue'

export default Vue.extend({
  components: {
    Loader
  },
  data () {
    return {
      apis: false,
    }
  },
  async created () {
    this.apis = await new APIs(this)
    
    // 若 localStorage 內有 accessToken 則走 logout
    if (getStoredAccessToken()) {
      this.logout()
    } else {
      this.$router.push({ path: "/login" }).catch(()=>{})
    }
  },
  methods: {
        // 打 Logout API
    async logout() {
      await this.apis.logoutAccount(this.logoutSuccess, this.logoutError)
    },
    // 登出處理回傳正常的回應
    logoutSuccess (_response) {
      if (_response.status === 200) {
        removeStoredAccessToken()
        removeStoredRefreshToken()
        this.$root.useAlert("success", "成功登出")

        setTimeout(() => {
          this.$router.push({ path: "/login" }).catch(()=>{})
        }, 1000)
      }
    },
    // 登出處理回傳異常的回應
    logoutError (_response) {
      this.$root.useAlert("red", _response.body.msg)
    },
  }
})
</script>
