<template>
  <fragment>
    <div class="venue-screen">
      <div
        class="close-img"
        :style="`background-image: url('${data.close_img}')`"
        @click="closeScreen(); setWatchTime(false)"
      ></div>
      <embed-frame max-width="100%">
        <video
          v-if="data.type === 'video'"
          :src="data.url"
          :poster="data.poster"
          autoplay
          controls
          playsinline
          loop
        />
        <v-img
          v-else-if="data.type === 'image'"
          :src="data.poster"
        />
        <iframe
          v-else-if="data.type === 'iframe'"
          :src="data.url"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </embed-frame>
    </div>
  </fragment>
</template>

<style lang="sass" scoped>
.venue

  &-screen
    margin: calc(100vh - (95% * 9 / 16) / 2 ) 0
    width: 95%
    @media screen and (min-width: 768px)
      width: 60%
      margin: calc(100vh - (60% * 9 / 16) / 2 ) 0
    .close-img
      background-repeat: no-repeat
      background-position: top right
      background-size: 1.4rem
      width: 100%
      padding-bottom: 1.4rem
      margin-bottom: .5rem
      cursor: pointer
      &:hover
        opacity: .8
      &:active
        opacity: .6
</style>

<script lang="ts">
import Vue from "vue";
import { Fragment } from "vue-fragment";
import EmbedFrame from "@/components/shared/EmbedFrame.vue";

export default Vue.extend({
  props: {
    data: Object,
  },
  components: {
    Fragment,
    EmbedFrame,
  },
  methods: {
    closeScreen() {
      this.$emit("closeScreen");
    },
    setWatchTime(str_end) {
      this.$emit("setWatchTime",str_end);
    },
  },
  mounted() {
    document.querySelector('.vid').play();
  }
});
</script>
