import Vue from 'vue'

export default class APIs {
    /**
     * 取得展覽區資訊
     */
    async getAreaInfo (_success, _error) {
        await Vue.http.get('exhibition/area').then(_success, _error)
    }
}
