<template>
  <div class="flags">
    <v-dialog
      v-model="dialogLeft"
      :content-class="`flag-dialog ${isBig ? 'isBig' : null}`"
      overlay-color="rgba(255, 255, 255, 0.8)"
      overlay-opacity="1"
      style='z-index:20001;'
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="flag flag-left"
          v-bind="attrs"
          v-on="on"
        >
          <div class="flag-image" :style="`background-image: url('${data.leftImage}')`"></div>
        </div>
      </template>
      <div class="flag-container">
        <div class="flag-close" @click="dialogLeft = false">
          <icon-base name="icon-close" color="#FFFFFF" />
        </div>
        <div class="flag-magnifier" @click="clickedOnMagnifier">
          <icon-base :name="isBig ? 'icon-magnifier-minus' : 'icon-magnifier-plus'" color="#FFFFFF" />
        </div>
        <v-img :src="data.leftImage" />
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialogRight"
      :content-class="`flag-dialog ${isBig ? 'isBig' : null}`"
      overlay-color="rgba(255, 255, 255, 0.8)"
      overlay-opacity="1"
      style='z-index:20001;'
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="flag flag-right"
          v-bind="attrs"
          v-on="on"
        >
          <div class="flag-image" :style="`background-image: url('${data.rightImage}')`"></div>
        </div>
      </template>
      <div class="flag-container">
        <div class="flag-close" @click="dialogRight = false">
          <icon-base name="icon-close" color="#FFFFFF" />
        </div>
        <div class="flag-magnifier" @click="clickedOnMagnifier">
          <icon-base :name="isBig ? 'icon-magnifier-minus' : 'icon-magnifier-plus'" color="#FFFFFF" />
        </div>
        <v-img :src="data.rightImage" />
      </div>
    </v-dialog>
  </div>
</template>

<style lang="sass" scoped>
  .flags
    overflow: auto

  .flag
    float: left
    position: relative
    width: 50%

    @media screen and (min-width: 768px)
      width: 11vw
      position: absolute

    &:before
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.58) 3.6%, rgba(255, 255, 255, 0.0898376) 9.33%, rgba(255, 255, 255, 0) 92.15%, rgba(0, 0, 0, 0) 100%)
      content: ''
      height: 100%
      left: 0
      position: absolute
      top: 0
      width: 100%

    &-container
      position: relative

    &-left
      top: 0

      @media screen and (min-width: 768px)
        left: 10%

    &-right
      top: 0

      @media screen and (min-width: 768px)
        right: 10%

    &-image
      background-size: cover
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)
      padding-bottom: calc(99.99% / 512 * 1024)

    &-close,
    &-magnifier
      cursor: pointer
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
      position: absolute
      right: 10px
      width: 30px
      z-index: 1

    &-close
      margin-left: auto
      top: 10px

    &-magnifier
      bottom: 10px
      margin: auto
</style>

<style lang="sass">
  .flag-dialog
    position: relative
    border-radius: 0
    width: 300px

    &.isBig
      max-height: 100%
      width: auto

  .v-overlay--active
    backdrop-filter: blur(4px)
</style>

<script lang="ts">
import Vue from 'vue'
import IconBase from '@/components/shared/IconBase.vue'

export default Vue.extend({
  props: {
    data: Object
  },
  components: {
    IconBase,
  },
  data () {
    return {
      dialogLeft: false,
      dialogRight: false,
      isBig: false,
    }
  },
  methods: {
    clickedOnMagnifier() {
      this.isBig = !this.isBig
    }
  },
  watch:{
    dialogLeft: function(value) {
      if (!value) {
        this.isBig = false
      }
    },
    dialogRight: function(value) {
      if (!value) {
        this.isBig = false
      }
    }
  }
})
</script>
