<template>
  <div class="not-in-time">
    <div class="not-in-time-container">
      <!-- <div class="not-in-time-title">Oops!</div> -->
      <template v-if="new Date().getTime() < new Date(this.$root.exhibition_data.start_at).getTime()">
        <div class="not-in-time-content mb-5">
          <h1 class="">展會尚未開始</h1>
          <h2 class="not-in-time-name mb-3">{{ this.$root.exhibition_data.name }}</h2>
          <p>{{ this.$root.exhibition_data.start_at | MMdd}}~{{ this.$root.exhibition_data.end_at | MMdd}} 開放上線</p>
        </div>
        <div class="not-in-time-footer" v-html="data.before_value"></div>
      </template>
      <template v-else-if="new Date(this.$root.exhibition_data.end_at).getTime() < new Date().getTime()">
        <div class="not-in-time-content mb-5">
          <h1 class="">展會已結束</h1>
          <h2 class="not-in-time-name">{{ this.$root.exhibition_data.name }}</h2>
        </div>
        <div class="not-in-time-footer"  v-html="data.after_value"></div>
      </template>
    </div>
  </div>
</template>

<style lang="sass">
  .not-in-time
    align-items: center
    background: linear-gradient(-45deg, #efefef, #ffffff, #efefef)
    display: flex
    flex-wrap: wrap
    justify-content: center
    min-height: 100vh
    padding-bottom: 70px

    @media screen and (min-width: 768px)
      font-size: 1.5em

    &-title
      font-size: 2em
      font-weight: bold
      margin-bottom: 1rem

    &-name
      font-weight: bold

    &-footer
      margin-top: 1em
      font-size: .7em
</style>

<script lang="ts">
import Vue from 'vue'
import exhibitionAPIs from "@/api/exhibition.js"

export default Vue.extend({
  name: 'NotInTime',
  data() {
    return {
      data: {
        after_value: Object,
        before_value: Object
      },
      apis: false,
    }
  },
  filters: {
    MMdd: function(time) {
      var date = new Date(time)
      var mon = (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))
      var day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))

      var str = mon + '/' + day
      return str
    }
  },
  async created() {
    this.apis = await new exhibitionAPIs(this)
    await this.apis.getExhibitionClose(this.getExhibitionSuccess, this.getExhibitionError);

    if(new Date().getTime() > new Date(this.$root.exhibition_data.start_at).getTime() && new Date(this.$root.exhibition_data.end_at).getTime() > new Date().getTime()){

      this.$router.push('/')
    }
  },
  methods: {
    getExhibitionSuccess (_response) {
      if (_response.status === 200) {
        this.data.after_value = _response.data.after_value
        this.data.before_value = _response.data.before_value
      }
    },
    getExhibitionError (_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken()
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
  }
})
</script>

<style scoped>
.mb-5{
  margin-bottom: 2.5rem !important;
}
.mb-3{
  margin-bottom: 1rem !important;
}
</style>
