<template>
  <form
    class="reset"
    @submit="onSubmitForm"
  >
    <div class="reset-window">
      <div class="reset-head">
        <a
          v-if="reset_data.banner"
          :href="reset_data.banner.link"
          :target="reset_data.banner.external ? '_blank': null"
        >
          <img
            :src="reset_data.banner.image"
            :alt="reset_data.banner.alt"
          >
        </a>
      </div>
      <div class="reset-content">
        <ul class="form-list">
          <li class="form-item" v-if="isVerify">
            <label
              class="form-label is-title"
              for="password"
            >
              <span>重設密碼<span class='form-required'>*</span></span>
            </label>
            <div class="form-content">
              <input
                v-model="form.password"
                class="form-control"
                type="password"
                name="reset-password"
                id="reset-password"
                autocomplete="reset-password"
                placeholder="請輸入欲設定的密碼"
                required
              >
            </div>
          </li>
          <li class="form-item text-center" v-if="!isVerify">
            <h2 class="mb-3 red--text text--lighten-1">
              無<template v-if="isVerify === false">效的</template>
              <template v-if="isVerify === null">帶入</template>驗證碼
            </h2>
            <p>請前往 <b>活動登入 > 忘記密碼</b> 重新取得驗證碼</p>
          </li>
        </ul>
      </div>
      <div class="reset-footer">
        <div class="reset-cta" v-if="isVerify">
          <button
            type="submit"
            class="form-button"
          >
            <img
              :src="reset_data.resetBtn.hover ? reset_data.resetBtn.imageActive : reset_data.resetBtn.image"
              :alt="reset_data.resetBtn.name"
              @mouseover="reset_data.resetBtn.hover = true"
              @mouseleave="reset_data.resetBtn.hover = false"
              @mousedown="reset_data.resetBtn.hover = true"
              @mouseup="reset_data.resetBtn.hover = false"
            >
          </button>
        </div>
        <a class="reset-func" href="/#/login">
          返回活動登入
        </a>
      </div>
    </div>
  </form>
</template>

<style lang="sass" scoped>
.reset
  align-items: center
  background: linear-gradient(180deg, #EDF5FB 0%, #B9DFF2 50.48%, #ABCBE1 100%)
  display: flex
  height: 100%
  justify-content: center
  overflow-x: hidden
  overflow-y: auto
  width: 100%

  &-window
    background: #ffffff
    display: flex
    flex-direction: column
    font-size: .9em
    height: 100%

    @media screen and (min-width: 768px)
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5)
      font-size: 1em
      height: auto
      max-height: 730px
      max-width: 500px

  &-content
    background: #ffffff
    flex: 1
    overflow-x: hidden
    overflow-y: auto

  &-footer
    img
      max-width: 200px
      width: 60%

  &-func
    border-top: 1px solid rgba(0, 0, 0, 0.5)
    color: inherit
    cursor: pointer
    display: block
    margin: 0 1em 0
    padding: 30px 0
    text-align: center
    text-decoration: none

  &-cta
    margin-bottom: 30px

  .form
    &-list
      list-style: none
      margin: 30px
      padding-left: 0
      text-align: left

      @media screen and (min-width: 768px)
        margin: 40px 64px

    &-item:not(:last-of-type)
      margin-bottom: 30px

      @media screen and (min-width: 768px)
        margin-bottom: 50px

    &-label
      display: block
      font-weight: bold
      margin-bottom: 6px
      padding-left: 1em

      &.is-title
        align-items: flex-end
        display: flex
        justify-content: space-between

    &-hint
      color: #a9a9a9
      flex: 1
      font-size: .9em
      font-weight: normal
      margin-left: 1em
      text-align: right

    &-required
      color: #FF0000

    &-control
      border-radius: 2em
      border: 1px solid #808080
      box-sizing: border-box
      display: block
      font-size: .9em
      padding: .8em 1em
      width: 100%

      @media screen and (min-width: 768px)
        font-size: 1em

      &:focus
        border-color: #444444
        outline: 0

    &-button
      max-width: 100%
      width: 70%
</style>


<script>
import Vue from "vue";
import APIs from "@/api/account.js"
import ExhibitionAPIs from "@/api/exhibition.js"

export default Vue.extend({
  data () {
    return {
      isVerify: null,
      token: null,
      apis: false,
      exhibition_apis: false,
      form: {
        password: null,
      },
      reset_data: {
        "resetBtn": {
          "name": "重設密碼",
          "hover": false,
          "image": "https://demo.exhibition.ictsage.com/storage/default/login/2/verify-cta.png",
          "imageActive": "https://demo.exhibition.ictsage.com/storage/default/login/2/verify-cta-active.png"
        },
      }
    };
  },
  async created () {
    this.token = this.$route.params.token
    this.apis = await new APIs(this)
    this.exhibition_apis = await new ExhibitionAPIs(this)
    this.getExhibitionLoginInfo()

    if (this.token) {
      this.checkResetAccountToken(this.token)
    }
  },
  methods: {
    onSubmitForm (e) {
      e.preventDefault();
      let { password } = this.form

      this.apis.resetAccount(
        this.token,
        password,
        this.resetAccountSuccess,
        this.resetAccountError
      )
    },
    async getExhibitionLoginInfo () {
      await this.exhibition_apis.getExhibitionLoginInfo(this.exhibitionLoginInfoSuccess, this.exhibitionLoginInfoError)
    },
    exhibitionLoginInfoSuccess (_response) {
      if (_response.status === 200) {
        this.reset_data = { ...this.reset_data, ..._response.body }
      }
    },
    exhibitionLoginInfoError (_response) {
      this.$root.useAlert("red", _response.body.msg)
    },
    async checkResetAccountToken (token) {
      await this.apis.checkResetAccountToken(token, this.checkResetAccountTokenSuccess, this.checkResetAccountTokenError)
    },
    checkResetAccountTokenSuccess (_response) {
      if (_response.status === 200) {
        this.isVerify = true
      }
    },
    checkResetAccountTokenError (_response) {
      this.$root.useAlert("red", _response.body.msg)
      this.isVerify = false
    },
    resetAccountSuccess () {
      this.$root.useAlert("success", "已重設密碼，請以新密碼進行登入")
      this.$router.push({ path: "/login" })
    },
    resetAccountError (_response) {
      this.$root.useAlert("red", _response.body.msg)
    }
  },
});
</script>
