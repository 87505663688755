<template>
  <!-- 背景圖片 -->
  <div
    class="venue"
    :style="`background-image: url('${venue_data.background}')`"
  >
    <div class="venue-banner">
      <top-banner :src="venue_data.banner" />
    </div>

    <!-- 主題區塊 -->
    <div class="theme-box">
      <theme-content
        v-for="(theme, index) in venue_data.theme_data"
        :key="index"
        :data="theme"
        :point_show="venue_data.point_show"
        :play_img="venue_data.play_img"
        :play_text="venue_data.play_text"
        @openScreen="openScreen"
        @setWatchTime="setWatchTime"
      />
    </div>

    <!-- 影片燈箱 -->
    <div class="screen-box" v-if="screen.is_show">
      <venue-screen
        :data="screen"
        @setWatchTime="setWatchTime"
        @closeScreen="closeScreen"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
.venue
  background-attachment: local
  background-color: #BBE1FE
  background-position: top center
  background-repeat: no-repeat
  background-size: cover
  bottom: 0
  display: flex
  flex-direction: column
  left: 0
  overflow: hidden
  position: absolute
  right: 0
  top: 0

  .theme-box
    flex-grow: 1
    width: 100%
    overflow: auto
    padding-bottom: 5rem

  .screen-box
    align-items: center
    display: flex
    height: 100%
    justify-content: center
    left: 0
    position: absolute
    top: 0
    transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms
    width: 100%
    z-index: 6
    outline: none
    z-index: 102
    background: rgba(0,0,0,.7)

  // 設定 Top Banner 在此頁的定位
  .top-banner
    @media screen and (min-width: 768px)
      margin-top: 2vw
      margin-bottom: 2vw
</style>

<script>
import Vue from "vue";
import ThemeContent from "@/components/pages/venue/theme-content.vue";
import VenueScreen from "@/components/pages/venue/VenueScreen.vue";
import TopBanner from "@/components/shared/TopBanner.vue";
import APIs from "@/api/venue.js";
import exhibitionAPIs from "@/api/exhibition.js"

export default Vue.extend({
  components: {
    ThemeContent,
    TopBanner,
    VenueScreen,
  },
  data: function () {
    return {
      apis: false,
      exhibitionAPIs: false,
      watch_time: String,
      active: {
        name: String,
        cate: String,
      },
      venue_data: {},

      //- 影片燈箱
      screen: {
        //- 影片燈箱顯示
        is_show: false,
        type: "iframe",
        url: "",
        close_img: "/venue/close-img.png",
      },
    };
  },
  async created() {
    this.apis = await new APIs(this);
    this.exhibitionAPIs = await new exhibitionAPIs(this)
    // 呼叫登入 API
    // 取得演講廳資訊
    await this.apis.getVenueInfo(this.getVenueSuccess, this.getVenueError);
  },
  methods: {
    setWatchTime(str_end, cate = null, name = null) {

      if(name && cate){
        this.active.name = name
        this.active.cate = cate
      }
      if(str_end){
        this.watch_time = new Date()
        console.log('紀錄觀看開始時間:' + new Date())
      }else{
        let time = Math.round((new Date() - this.watch_time) / 1000);
        console.log('觀看時間:'+ time)

        this.createVenueTimeEvent(time)
        this.watch_time = ''
      }

    },
    createVenueTimeEvent(time) {
      const vm = this
      const parameter = {
        "event": "venue.watch",
        "data": {
            "cate": vm.active.cate,
            "name": vm.active.name,
            "play_time": time
        }
      };
      this.exhibitionAPIs.createEvent(parameter, this.createEventSuccess, this.createEventError);
    },
    createEventSuccess (_response) {
      if (_response.status === 200) {
        this.active.cata = ''
        this.active.name = ''
        console.log('成功紀錄分會場影片觀看時間')
      }
    },
    createEventError (_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken()
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    openScreen(_data = "") {
      this.screen.url = _data.video_url;
      this.screen.type = _data.video_type ? _data.video_type : 'iframe'
      this.screen.is_show = true;
    },
    closeScreen() {
      this.screen.is_show = false;
      this.screen.url = "";
      this.screen.type = 'iframe'
    },
    // 取資料處理回傳正常的回應
    getVenueSuccess(_response) {
      if (_response.status === 200) {
        this.venue_data = _response.body;
        console.log("成功取得分會場資訊");
      }
    },
    // 取資料登入處理回傳異常的回應
    getVenueError(_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
  },
});
</script>
