<template>
  <form
    class="login"
    @submit="onSubmitForm"
  >
    <div class="login-window">
      <div class="login-head">
        <a
          v-if="login_data.banner"
          :href="login_data.banner.link"
          :target="login_data.banner.external ? '_blank': null"
        >
          <img
            :src="login_data.banner.image"
            :alt="login_data.banner.alt"
          >
        </a>
      </div>
      <div class="login-content">
        <ul class="form-list">
          <template v-if="isLoginPanel">
            <li class="form-item">
              <label
                class="form-label is-title"
                for="username"
              >
                <span>帳號<span class='form-required'>*</span></span>
                <span class="form-hint">{{ login_data.username_hint }}</span>
              </label>
              <div class="form-content">
                <input
                  v-model="form.username"
                  class="form-control"
                  type="text"
                  name="username"
                  id="username"
                  autocomplete="current-username"
                  placeholder="請輸入帳號"
                >
              </div>
            </li>
            <li class="form-item">
              <label
                class="form-label is-title"
                for="password"
              >
                <span>密碼<span class='form-required'>*</span></span>
                <span class="form-hint">{{ login_data.password_hint }}</span>
              </label>
              <div class="form-content">
                <input
                  v-model="form.password"
                  class="form-control"
                  type="password"
                  name="password"
                  id="password"
                  autocomplete="current-password"
                  placeholder="請輸入密碼"
                >
              </div>
            </li>
            <li class="form-item">
              <label
                for="remember_me"
                class="form-label"
              >
                <input
                  v-model="form.remember_me"
                  type="checkbox"
                  name="remember_me"
                  id="remember_me"
                >
                記住我
              </label>
            </li>
          </template>
          <template v-else>
            <li class="form-item">
              <label
                class="form-label"
                for="email"
              >E-mail<span class='form-required'>*</span></label>
              <div class="form-content">
                <input
                  v-model="form.email"
                  class="form-control"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="請輸入電子郵件"
                >
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="login-footer">
        <div class="login-cta">
          <button
            type="submit"
            class="form-button"
          >
            <template v-if="isLoginPanel">
              <img
                v-if="login_data.loginBtn"
                :src="login_data.loginBtn.hover ? login_data.loginBtn.imageActive : login_data.loginBtn.image"
                :alt="login_data.loginBtn.name"
                @mouseover="login_data.loginBtn.hover = true"
                @mouseleave="login_data.loginBtn.hover = false"
                @mousedown="login_data.loginBtn.hover = true"
                @mouseup="login_data.loginBtn.hover = false"
              >
            </template>
            <template v-else>
              <img
                :src="login_data.verifyBtn.hover ? login_data.verifyBtn.imageActive : login_data.verifyBtn.image"
                :alt="login_data.verifyBtn.name"
                @mouseover="login_data.verifyBtn.hover = true"
                @mouseleave="login_data.verifyBtn.hover = false"
                @mousedown="login_data.verifyBtn.hover = true"
                @mouseup="login_data.verifyBtn.hover = false"
              >
            </template>
          </button>
        </div>
        <div class="login-func">
          <span @click="isLoginPanel = !isLoginPanel">
            <template v-if="isLoginPanel">忘記密碼</template>
            <template v-else>返回登入</template>
          </span>
        </div>
      </div>
    </div>
  </form>
</template>

<style lang="sass" scoped>
.login
  align-items: center
  background: linear-gradient(180deg, #EDF5FB 0%, #B9DFF2 50.48%, #ABCBE1 100%)
  display: flex
  height: 100%
  justify-content: center
  overflow-x: hidden
  overflow-y: auto
  width: 100%

  &-window
    background: #ffffff
    display: flex
    flex-direction: column
    font-size: .9em
    height: 100%

    @media screen and (min-width: 768px)
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5)
      font-size: 1em
      height: auto
      max-height: 730px
      max-width: 500px

  &-content
    background: #ffffff
    flex: 1
    overflow-x: hidden
    overflow-y: auto

  &-footer
    img
      max-width: 200px
      width: 60%

  &-func
    border-top: 1px solid rgba(0, 0, 0, 0.5)
    cursor: pointer
    margin: 0 1em 0
    padding: 30px 0
    text-align: center

  &-cta
    margin-bottom: 30px

  .form
    &-list
      list-style: none
      margin: 30px
      padding-left: 0
      text-align: left

      @media screen and (min-width: 768px)
        margin: 40px 64px

    &-item:not(:last-of-type)
      margin-bottom: 30px

      @media screen and (min-width: 768px)
        margin-bottom: 50px

    &-label
      display: block
      font-weight: bold
      margin-bottom: 6px
      padding-left: 1em

      &.is-title
        align-items: flex-end
        display: flex
        justify-content: space-between

    &-hint
      color: #a9a9a9
      flex: 1
      font-size: .9em
      font-weight: normal
      margin-left: 1em
      text-align: right

    &-required
      color: #FF0000

    &-control
      border-radius: 2em
      border: 1px solid #808080
      box-sizing: border-box
      display: block
      font-size: .9em
      padding: .8em 1em
      width: 100%

      @media screen and (min-width: 768px)
        font-size: 1em

      &:focus
        border-color: #444444
        outline: 0

    &-button
      max-width: 100%
      width: 70%
</style>


<script>
import Vue from "vue";
import APIs from "@/api/account.js"
import ExhibitionAPIs from "@/api/exhibition.js"
import {
  setStoredAccessToken,
  setStoredRefreshToken,
  getStoredLoginUsername,
  setStoredLoginUsername,
  removeStoredLoginUsername,
} from '@/utils/auth'

export default Vue.extend({
  data () {
    return {
      apis: false,
      exhibition_apis: false,
      isLoginPanel: true,
      form: {
        email: null,
        password: null,
        username: null,
        remember_me: null
      },
      login_data: {}
    };
  },
  async created () {
    let storedLoginUsername = getStoredLoginUsername()
    this.apis = await new APIs(this)
    this.exhibition_apis = await new ExhibitionAPIs(this)
    this.getExhibitionLoginInfo()
    
    // 若 localStorage 內有儲存的 username 設定於 form 及 remember_me
    if (storedLoginUsername) {
      this.form.username = storedLoginUsername
      this.form.remember_me = true
    }
  },
  methods: {
    // 傳送表單 (登入/忘記密碼共用)
    onSubmitForm (e) {
      e.preventDefault();
      if (this.isLoginPanel) {
        let { username, password, remember_me } = this.form

        // 呼叫登入 API
        this.apis.loginAccount(
          username,
          password,
          this.loginSuccess,
          this.loginError
        )

        if (remember_me) {
          setStoredLoginUsername(username)
        } else {
          removeStoredLoginUsername()
        }
      } else {
        let { email } = this.form

        // 取得重設密碼 Token
        this.apis.getResetAccountToken(
          email,
          this.resetAccountTokenSuccess,
          this.resetAccountTokenError
        )
      }
    },
    // 登入處理回傳正常的回應
    loginSuccess (_response) {
      if (_response.status === 200) {
        setStoredAccessToken(_response.body.accessToken)
        setStoredRefreshToken(_response.body.refreshToken)
        this.$router.push({ path: "/" })
        this.$root.isLogin = true
      }
    },
    // 登入處理回傳異常的回應
    loginError (_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken()
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    async getExhibitionLoginInfo () {
      await this.exhibition_apis.getExhibitionLoginInfo(this.exhibitionLoginInfoSuccess, this.exhibitionLoginInfoError)
    },
    exhibitionLoginInfoSuccess (_response) {
      if (_response.status === 200) {
        this.login_data = { ...this.login_data, ..._response.body }
      }
    },
    exhibitionLoginInfoError (_response) {
      this.$root.useAlert("red", _response.body.msg)
    },
    resetAccountTokenSuccess () {
      this.$root.useAlert("success", "已寄送重設密碼信，請前往信箱收信")
    },
    resetAccountTokenError (_response) {
      this.$root.useAlert("red", _response.body.msg)
    }
  },
});
</script>
