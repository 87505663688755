<template>
  <div
    class="lobby-slider"
    v-if="slider_data.length !== 0"
  >
    <splide :options="splideOption">
      <splide-slide
        v-for="(item, index) in slider_data"
        v-bind:key="item.name"
      >
        <embed-frame>
          <div
            class="url-box"
            v-if="item.type === 'image'"
          >
            <a
              v-if="item.url"
              :href="item.url"
              :target="item.external === true ? '_blank' : null"
            >
              <v-img :src="item.poster" />
            </a>
            <v-img
              v-else
              :src="item.poster"
            />
          </div>

          <video
            :poster="item.poster"
            :src="item.url"
            autoplay
            controls
            muted
            playsinline
            v-if="
              item.type === 'video' &&
              swiperCurrent.index === index &&
              swiperCurrent.isPlaying === true
            "
          />
          <iframe
            :src="item.url"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            v-if="
              item.type === 'iframe' &&
              swiperCurrent.index === index &&
              swiperCurrent.isPlaying === true
            "
          />
          <v-img
            :src="item.poster"
            @click="play"
            v-if="
              item.type !== 'image' &&
              swiperCurrent.isPlaying === false
            "
          />
        </embed-frame>
      </splide-slide>
    </splide>
  </div>
</template>

<style lang="sass">
.lobby-slider
  padding: 10px 0
  position: relative

  @media screen and (min-width: 768px)
    left: 3%
    margin: 0
    max-width: none
    padding-left: 1.2vw
    padding-right: 1.3vw
    padding-top: 0.5vw
    position: absolute
    top: 67%
    width: 17vw

    &:before
      background-position: center
      background-repeat: no-repeat
      background-size: contain
      content: ''
      height: 100%
      left: 0
      padding-bottom: calc(99.99% / 1200 * 780)
      position: absolute
      top: 0
      width: 100%

      @media screen and (min-width: 768px)
        background-image: url('/lobby/slider.png')

  .splide__pagination
    background: #FFFFFF
    border-radius: 10px
    bottom: 8%
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25)
    display: flex
    height: 6px
    left: 50%
    margin: 10px auto 0
    padding-left: 0
    position: static
    transform: none
    width: 90%
    z-index: 100

    @media screen and (min-width: 768px)
      bottom: -14%

    @media screen and (min-width: 1920px)
      bottom: -11%

    li
      align-items: center
      display: flex
      flex: 1
      justify-content: center
      position: relative

    &__page
      background: transparent
      flex: 1
      height: 6px
      margin: 0
      padding: 0
      transition: background .5s
      width: 100%

      &.is-active
        background: #FFA800
        border-radius: 10px
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        transform: none
</style>

<script>
import EmbedFrame from '@/components/shared/EmbedFrame.vue'
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { convertInternalLink } from '@/utils/convertInternalLink'

import Vue from 'vue'
export default Vue.extend({
  components: {
    EmbedFrame,
    Splide,
    SplideSlide,
  },
  props: {
    slider_data: Array
  },
  mounted () {
    this.slider_data.map(item => {
      item.url = convertInternalLink(item.url)
    })
  },
  data () {
    return {
      splideOption: {
        arrows: false,
        pagination: 'slider-bullets',
        // page: 'slider-bullet',
        autoplay: true,
        interval: 2000,
        page: 'slider-bullet',
        type: 'loop',
        width: '100%',
      }
    }
  },
})
</script>
