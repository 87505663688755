<template>
  <div class="area-large-content">
    <div
      class="swiper-wrapper"
      @mouseenter="onSlideMouseMovement"
      @mouseleave="onSlideMouseMovement"
      :style="`max-height: ${data.length > 4 ? '100%' : '50%'}`"
    >
      <swiper
        :options="swiperAreaOption"
        @slideChangeTransitionStart="onSlideChangeTransitionStart"
        @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
        class="swiper"
        ref="refSwiper"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="n in Math.ceil(data.length / 4)"
          :key="n"
        >
          <area-item
            class="js-listen-event"
            :data-event-name="item.name"
            v-for="(item, index) in data.slice((n - 1) * 4, 4 + (n - 1) * 4)"
            :key="index"
            :data="item"
          />
        </swiper-slide>
      </swiper>
    </div>
    <div class="swiper-control" v-if="data.length > 4">
      <!-- 向上箭頭 -->
      <div
        class="swiper-button-prev"
        :style="`background-image: url('${nav.arrow_up}')`"
      ></div>

      <div class="area-swiper-pagination"></div>

      <!-- 向下箭頭 -->
      <div
        class="swiper-button-next"
        :style="`background-image: url('${nav.arrow_down}')`"
      ></div>
    </div>
  </div>
</template>
<style lang="sass" scoped>
.area-large-content
  margin-top: 16%
  width: 100%
  height: 66%
  overflow: hidden
  display: none
  position: relative
  @media screen and (min-width: 768px)
    display: flex

  .swiper-container
    width: 100%
    max-height: 100%
    margin-left: auto
    margin-right: auto
    padding: 0 10px
    padding: 0 40px 0 10px
    @media screen and (min-width: 1300px)
      padding: 0 60px
  .swiper-slide
    text-align: center
    font-size: 48px
    font-weight: 500
    display: -webkit-box
    display: -ms-flexbox
    display: -webkit-flex
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    -webkit-justify-content: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    -webkit-align-items: center
    align-items: center

  .swiper-control
    position: absolute
    height: 90%
    width: 38px
    top: 10%
    right: .5rem

  .area-swiper-pagination
    text-align: center
    transition: 300ms opacity
    transform: translate3d(0, 0, 0)
    z-index: 10
    // height: 56%
    width: 38px
    border-radius: 30px
    background: rgba(25, 71, 138, 0.8)
    position: relative
    padding: 1rem 0

  .swiper-button-next::after
    content: ''
  .swiper-button-next
    width: 100%
    right: auto !important
    top: auto !important
    display: block
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    position: relative
    margin-top: 1rem
    &:hover
      opacity: .8
    &:active
      opacity: .6

  .swiper-button-prev::after
    content: ''
  .swiper-button-prev
    width: 38px
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    position: relative
    right: auto !important
    left: auto !important
    top: auto !important
    display: block
    margin-bottom: 1rem
    &:hover
      opacity: .8
    &:active
      opacity: .6
</style>
<style lang='sass'>
.my-swiper-pagination-bullet
  width: 7px
  height: 7px
  display: block
  border-radius: 50%
  background: #fff
  opacity: 1 !important
  margin: 1rem auto
  text-align: center

.my-bullet-active
  width: 7px
  height: 7px
  display: block
  border-radius: 50%
  opacity: 1 !important
  text-align: center
  background: #FFD600 !important
  --swiper-theme-color: #FFD600 !important
</style>
<script>
import Vue from "vue";
import AreaItem from "@/components/pages/area/AreaItem.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Navigation, Pagination]);
import APIs from "@/api/exhibition.js"

export default Vue.extend({
  components: {
    Swiper,
    SwiperSlide,
    AreaItem,
  },
  props: {
    data: Array,
    nav: Object,
  },
  computed: {
    swiper() {
      return this.$refs.refSwiper.$swiper;
    },
  },
  async created() {
    this.apis = await new APIs(this)
  },
  mounted() {
    const vm = this;
    this.autoplay = true;
    this.initTimer();

    document.querySelectorAll(".js-listen-event").forEach(function (event) {
      event.addEventListener("click", function () {
        vm.createAreaEvent(event.dataset.eventName);
      });
    });
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  data() {
    return {
      swiperAreaOption: {
        loop: true,
        direction: "vertical",
        slidesPerView: this.data.length > 4 ? 2 : 1,
        mousewheel: true,
        pagination: {
          el: ".area-swiper-pagination",
          clickable: true,
          bulletClass: "my-swiper-pagination-bullet",
          bulletActiveClass: "my-bullet-active",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      changeTime: 8000,
      timer: null,
      autoplay: true,
    };
  },
  methods: {
    createAreaEvent(name) {
      const parameter = {
        event: "area.redirect",
        data: {
          name: name,
        },
      };

      this.apis.createEvent(
        parameter,
        this.createEventSuccess,
        this.createEventError
      );
    },
    createEventSuccess (_response) {
      if (_response.status === 200) {
        console.log('成功紀錄分展示區攤位點擊')
      }
    },
    createEventError (_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken()
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    handleAutoplay: function () {
      if (this.autoplay) {
        this.swiper.slideNext();
      }
    },
    initTimer: function () {
      this.timer = setTimeout(this.handleAutoplay, this.changeTime);
    },
    onSlideChangeTransitionStart: function () {
      this.autoplay = false;
      clearTimeout(this.timer);
    },
    onSlideChangeTransitionEnd: function () {
      this.autoplay = true;
      this.initTimer();
    },
    onSlideMouseMovement: function (e) {
      if (e.type === 'mouseleave') {
        this.autoplay = true
        this.initTimer()
      }

      if (e.type === 'mouseenter') {
        this.autoplay = false
        clearTimeout(this.timer)
      }
    },
  },
});
</script>
