<template>
  <div
    class="lobby"
    v-if="lobby_data.background"
    :style="`
      background-color: ${lobby_data.background.bgcolor};
      background-image: url('${isDesktop ? lobby_data.background.desktop : lobby_data.background.mobile}');
    `"
  >
    <container>
      <lobby-title :data="lobby_data.title" />
      <lobby-forum :data="lobby_data.forum" />
      <lobby-table :data="lobby_data.table" />
      <lobby-greeting :data="lobby_data.greeting" />
      <lobby-session :data="lobby_data.session" />
      <lobby-slider :slider_data="lobby_data.slider" />
      <lobby-flags :data="lobby_data.flags" />
      <lobby-sign :data="lobby_data.sign" />
      <!-- <lobby-deco :data="lobby_data.deco" /> -->
    </container>
  </div>
</template>

<style lang="sass" scoped>
  .lobby
    background-position: top center
    background-repeat: no-repeat
    background-size: 120%
    min-height: 100%

    @media screen and (min-width: 768px)
      background-size: cover
</style>

<script>
import Vue from 'vue'
import Container from '@/components/shared/Container.vue'
import LobbyTitle from '@/components/pages/lobby/LobbyTitle.vue'
import LobbyGreeting from '@/components/pages/lobby/LobbyGreeting.vue'
import LobbyFlags from '@/components/pages/lobby/LobbyFlags.vue'
import LobbyForum from '@/components/pages/lobby/LobbyForum.vue'
import LobbyTable from '@/components/pages/lobby/LobbyTable.vue'
import LobbySlider from '@/components/pages/lobby/LobbySlider.vue'
import LobbySign from '@/components/pages/lobby/LobbySign.vue'
// import LobbyDeco from '@/components/pages/lobby/LobbyDeco.vue'
import LobbySession from '@/components/pages/lobby/LobbySession.vue'
import APIs from "@/api/lobby.js";

export default Vue.extend({
  mounted () {
    if (window.innerWidth >= 768) {
      this.isDesktop = true
    } else {
      this.isDesktop = false
    }
  },
  destroyed () {
    window.removeEventListener("resize", this.detectResize)
  },
  methods: {
    detectResize (e) {
      if (e.currentTarget.innerWidth >= 768 && !this.isDesktop) {
        this.isDesktop = true
      }

      if (e.currentTarget.innerWidth < 768 && this.isDesktop) {
        this.isDesktop = false
      }
    },
    // 取資料處理回傳正常的回應
    getLobbySuccess (_response) {
      if (_response.status === 200) {
        this.lobby_data = _response.body
        console.log('成功取得入口大廳資訊')
      }
    },
    // 取資料處理回傳異常的回應
    getLobbyError (_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken()
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    }
  },
  components: {
    Container,
    // LobbyDeco,
    LobbyFlags,
    LobbyForum,
    LobbyGreeting,
    LobbySession,
    LobbySign,
    LobbySlider,
    LobbyTable,
    LobbyTitle,
  },
  async created () {
    window.addEventListener("resize", this.detectResize)
    this.apis = await new APIs(this);
    // 呼叫登入 API
    // 取得演講廳資訊
    await this.apis.getLobbyInfo(this.getLobbySuccess, this.getLobbyError);
  },
  data: function () {
    return {


      apis: false,
      exhibition_apis: false,
      lobby_data: {},
      /**
       * @isDesktop 判定是否為電腦版
       * 此功能用於大廳背景選用手機版或電腦版
       */
      isDesktop: false,
      // /**
      //  * @title 活動名稱
      //  */
      // title: '2021行動科技解決方案體驗日',
      // /**
      //  * @background 大廳底圖設計
      //  * desktop - 電腦版底圖
      //  * mobile - 手機版底圖 (使用範圍為活動名稱區域)
      //  * bgcolor - 手機版活動名稱區域下方範圍全部底色
      //  */
      // background: {
      //   desktop: '/lobby/bg.jpg',
      //   mobile: '/lobby/bg-mobile.jpg',
      //   bgcolor: '#D3EAF6'
      // },
      // /**
      //  * @table 諮詢台
      //  * tableColor - 檯子底色顏色
      //  * tableImage - 檯子樣式 (含人與電腦範圍)
      //  * logoImage - LOGO 圖片
      //  */
      // table: {
      //   tableColor: 'transparent',
      //   tableImage: '/lobby/table.png',
      //   logoImage: '/lobby/logo.png',
      // },
      // /**
      //  * @greeting 左下招呼模組
      //  * image - 人物圖片
      //  * wording - 招呼語內文
      //  */
      // greeting: {
      //   image: '/lobby/greeting-kv.png',
      //   wording: '早安！ＯＯＯ！',
      // },
      // /**
      //  * @flags 大廳左右旗幟
      //  * leftImage - 左側旗幟
      //  * rightImage - 右側旗幟
      //  */
      // flags: {
      //   leftImage: '/lobby/flag-left.png',
      //   rightImage: '/lobby/flag-right.png',
      // },
      // /**
      //  * @slider 左側螢幕
      //  * @params slider type
      //  * - image: url = <img> src.
      //  * - video: url = <video> src; poster = <video> poster.
      //  * - iframe: url = <iframe> src (suitable to youtube embed link, website); poster = <img> src.
      //  * 
      //  * video, iframe 皆會有 poster 欄位，以利滑播時以單純圖片取代
      //  * 降低載入速度低落及未暫停影片導致背景撥放之問題
      //  */
      // slider: [
      //   {
      //     name: '滑播第一頁',
      //     type: 'image',
      //     url: '/lobby/slider.jpg',
      //   },
      //   {
      //     name: '滑播第二頁',
      //     type: 'video',
      //     url: '/lobby/sample.mp4',
      //     poster: '/lobby/sample.png',
      //   },
      //   {
      //     name: '滑播第三頁',
      //     type: 'iframe',
      //     url: 'https://www.youtube.com/embed/rZDtgMCun-0?autoplay=1&mute=1&enablejsapi=1',
      //     poster: '/lobby/sample.png',
      //   },
      // ],
      // /**
      //  * @sign 左側指示牌
      //  * bgImage - 底座
      //  * screenImage - 螢幕
      //  * link1, link2 - 連結1,2 區域 (各占比螢幕上半、下半)
      //  */
      // sign: {
      //   bgImage: '/lobby/sign-bg.png',
      //   screenImage: '/lobby/sign-screen.png',
      //   link1: {
      //     name: '大會議程',
      //     url: 'https://yuanta2021.ictsage.com/schedule.html?key={{name}}-{{phone}}'
      //   },
      //   link2: {
      //     name: '活動好禮',
      //     url: 'http://webdev.digitalnow.com.tw/junipersolutionday/Activity.html#gift1'
      //   },
      // },
      // /**
      //  * @session 分會場入口
      //  * entrance - 大門樣式
      //  * deco - 前方裝飾
      //  */
      // session: {
      //   entrance: '/lobby/session-entrance.png',
      //   deco: '/lobby/session-deco.png'
      // },
      // /**
      //  * @deco 裝飾人物
      //  * personTableLeft - 諮詢台左方人物
      //  * personTableRight - 諮詢台右方人物
      //  * personEscalator - 電扶梯下方人物
      //  * groupRight - 沙發旁邊一群人物
      //  */
      // deco: {
      //   personTableLeft: '/lobby/person-table-left.png',
      //   personTableRight: '/lobby/person-table-right.png',
      //   personEscalator: '/lobby/person-escalator.png',
      //   groupRight: '/lobby/group-right.png',
      // },
      // /**
      //  * @forum 中間演講廳
      //  * @params forum type
      //  * - image: url = <img> src.
      //  * - video: url = <video> src; poster = <video> poster. 
      //  * - iframe: url = <iframe> src (suitable to youtube embed link, website).
      //  */
      // forum: {
      //   type: 'video',
      //   url: '/lobby/sample.mp4',
      //   poster: '/lobby/sample.png',
      //   cta: {
      //     name: '進入議程',
      //     image: '/lobby/forum-cta.png',
      //     imageActive: '/lobby/forum-cta-active.png',
      //     hover: false,
      //   }
      // },
    }
  },
})
</script>
