import Vue from 'vue'

export default class APIs {
    /**
     * 取得入口大廳資訊
     */
    async getLobbyInfo (_success, _error) {
        await Vue.http.get('exhibition/entrance').then(_success, _error)
    }
}
