<template>
  <div
    class="lobby-sign"
    v-if="data.display"
  >
    <div
      :style="`background-image: url('${data.bgImage}')`"
      class="lobby-sign-bg"
    />
    <div class="lobby-sign-screen">
      <div
        :style="`background-image: url('${data.screenImage}')`"
        class="lobby-sign-screen-bg"
      />
      <a
        :href="data.link1.url"
        class="lobby-sign-link lobby-sign-link-top"
        :target="data.link1.external ? '_blank' : null"
      >{{ data.link1.name }}</a>
      <a
        :href="data.link2.url"
        class="lobby-sign-link lobby-sign-link-bottom"
        :target="data.link2.external ? '_blank' : null"
      >{{ data.link2.name }}</a>
    </div>
  </div>
</template>

<style lang="sass">
.lobby-sign
  display: none

  @media screen and (min-width: 768px)
    display: block
    left: 25vw
    position: absolute
    top: 65%
    width: 6vw

  &-bg,
  &-screen-bg
    background-position: center
    background-repeat: no-repeat
    background-size: contain

  &-bg
    padding-bottom: calc(99.99% / 512 * 1028)

  &-screen
    left: 15%
    position: absolute
    top: 3%
    width: 71%

    &-bg
      padding-bottom: calc(99.99% / 512 * 796)

  &-link
    align-items: center
    display: flex
    font-size: 0
    height: 50%
    justify-content: center
    position: absolute
    width: 100%

    &-top
      top: 0

    &-bottom
      top: 50%
</style>

<script>
import { convertInternalLink } from '@/utils/convertInternalLink'

export default {
  props: {
    data: Object
  },
  mounted () {
    this.data.link1.url = convertInternalLink(this.data.link1.url)
    this.data.link2.url = convertInternalLink(this.data.link2.url)
  }
}
</script>
