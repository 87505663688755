<template>
  <div class="lecture-bottom">
    <!-- lecture 字樣 -->
    <div
      v-if="data.sign_img"
      class="sign-img"
      :style="`background-image: url('${data.sign_img}')`"
    ></div>

    <!-- 舞台上的人 -->
    <div
      v-if="data.speaker_img"
      class="speaker-img"
      :style="`background-image: url('${data.speaker_img}')`"
    ></div>

    <!-- 舞台 -->
    <div
      v-if="data.stage_img"
      class="stage-img"
      :style="`background-image: url('${data.stage_img}')`"
    ></div>

    <!-- 觀眾 -->
    <div
      v-if="data.audience_img"
      class="audience-img"
      :style="`background-image: url('${data.audience_img}')`"
    ></div>

    <!-- 淺藍色地毯 -->
    <div class="water-blue-floor"></div>
  </div>
</template>

<style lang="sass" scoped>
.lecture-bottom
  flex-grow: 1
  position: absolute
  bottom: 0rem
  left: 0
  width: 100%
  height: 27rem
  margin-top: 2rem
  pointer-events: none
  @media (min-width: 500px)
    bottom: 0.3rem
  @media (min-width: 800px)
    bottom: 0
  // @media (min-width: 1279px)
  //   width: 80%
  @media (min-width: 1700px)
    height: 38rem

  .sign-img
    background-position: bottom center
    background-repeat: no-repeat
    background-size: contain
    position: absolute
    right: 0
    top: 0
    width: 84%
    left: calc((100% - 84%) /2 )
    bottom: 22rem
    z-index: 4
    @media (min-width: 370px)
      bottom: 22rem
      width: 90%
      left: calc((100% - 90%) /2 )
    @media (min-width: 500px)
      bottom: 21rem
      width: 25rem
      left: calc((100% - 25rem) /2 )
    @media (min-width: 768px)
      width: 20%
      left: 12%
      bottom: 7rem
    @media (min-width: 900px)
      width: 24%
      left: 10%
      bottom: 8rem
    @media (min-width: 1200px)
      width: 29%
      left: 2%
      bottom: 10rem
    @media (min-width: 1700px)
      bottom: 12rem

  .speaker-img
    background-position: bottom center
    background-repeat: no-repeat
    background-size: contain
    position: absolute
    left: calc((100% - 800px) /2)
    right: 0
    top: 0
    width: 80%
    bottom: 6.2rem
    z-index: 3
    min-width: 800px
    max-width: none
    @media (min-width: 600px)
      bottom: 8.5rem
      min-width: none
    @media (min-width: 768px)
      min-width: 500px
      width: 50%
      left: 35%
      bottom: 5.8rem
    @media (min-width: 900px)
      width: 50%
      left: 38%
      bottom: 6.8rem
    @media (min-width: 1200px)
      width: 50%
      left: 40%
      bottom: 8rem
    @media (min-width: 1700px)
      bottom: 10rem

  .stage-img
    margin-bottom: 2rem
    width: 100%
    background-position: bottom center
    background-repeat: no-repeat
    background-size: contain
    position: absolute
    bottom: 1rem
    left: calc((1280px - 100%) /2 *-1 )
    right: 0
    top: 0
    z-index: 2
    min-width: 1280px
    @media (min-width: 768px)
      bottom: 0rem
      left: 0
      min-width: 0
    @media (min-width: 1300px)
      left: 0

  .audience-img
    z-index: 3
    width: 100%
    min-width: 1100px
    background-position: bottom center
    background-repeat: no-repeat
    background-size: contain
    bottom: 0
    left: calc((1100px - 100%) /2 *-1 )
    right: 0
    top: 0
    position: absolute
    @media (min-width: 1100px)
      left: 0

  .water-blue-floor
    position: absolute
    left: 0
    bottom: 0
    width: 100%

    height: 7rem
    background: #BCDDF0
    z-index: 1
    @media (min-width: 500px)
      bottom: 0
</style>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    data: Object,
  },
});
</script>
