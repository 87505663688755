<template>
  <!-- INFO: 非活動區間則不顯示Navbar -->
  <nav v-if="this.$root.isInTime && options">
    <div class="nav-menu" :class="isOpen ? 'active' : null">
      <div
        class="swiper-button"
        :class="currentItem === 0 ? 'disabled' : ''"
        ref="refPrev"
        @click="clickedPrev"
      >
        <icon-base name="icon-prev" color="#AEAEAE"></icon-base>
      </div>
      <swiper
        :options="swiperOption"
        class="swiper"
        ref="refSwiper"
        v-if="options.length > 0"
        @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
      >
        <swiper-slide v-for="option in options" v-bind:key="option.name">
          <a
            class="nav-menu-item"
            :class="
              $route.path === option.url.replace('#', '') ? 'is-current' : ''
            "
            :href="option.url"
            :target="option.external === true ? '_blank' : null"
            @click="createTabEvent(option.name)"
          >
            <div class="nav-menu-item-icon">
              <img class="nav-menu-item-image is-default" :src="option.img" />
              <img
                class="nav-menu-item-image is-active"
                :src="option.imgActive"
              />
            </div>
            <span class="nav-menu-item-name">{{ option.name }}</span>
          </a>
        </swiper-slide>
      </swiper>
      <div
        class="swiper-button"
        :class="
          currentItem + slidesPerView === options.length ? 'disabled' : ''
        "
        ref="refNext"
        @click="clickedNext"
      >
        <icon-base name="icon-next" color="#AEAEAE"></icon-base>
      </div>
    </div>
    <div class="nav-btn" @click="toggleNav">
      <icon-base :name="isOpen ? 'icon-down' : 'icon-more-app'"></icon-base>
    </div>
  </nav>
</template>

<style lang="sass" scoped>
nav
  bottom: 0px
  left: 50%
  list-style: none
  max-width: 900px
  pointer-events: none
  position: fixed
  transform: translateX(-50%)
  width: 100vw
  z-index: 1001

  .nav-btn
    align-items: center
    background: #ffffff
    border-radius: 50%
    bottom: 10px
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
    display: none
    height: 30px
    justify-content: center
    left: 50%
    pointer-events: auto
    position: fixed
    transform: translateX(-50%)
    width: 30px
    z-index: 1

    @media screen and (min-width: 768px)
      display: flex

    svg
      fill: #000000
      width: 18px

  .nav-menu
    background: #ffffff
    border-radius: 10px 10px 0 0
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1)
    display: flex
    pointer-events: auto
    position: relative

    @media screen and (min-width: 768px)
      border-radius: 18px
      bottom: 50px
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)
      margin: 0 1em
      opacity: 0
      pointer-events: none
      transform: translateY(20%)
      transition: all .2s

    &.active
      opacity: 1
      pointer-events: auto
      transform: translateY(0)

    &-item
      align-items: center
      color: #AEAEAE
      display: flex
      flex-direction: column
      justify-content: center
      padding: 6px .2em 14px
      text-decoration: none

      @media screen and (min-width: 768px)
        padding: 12px .2em 12px

      @media screen and (min-width: 1024px)
        padding: 8px .2em 8px

      &:hover,
      &:focus,
      &.is-current
        .nav-menu-item-image
          &.is-default
            opacity: 0

          &.is-active
            opacity: 1

        .nav-menu-item-name
          color: #666666

      &-icon
        height: 22px
        position: relative
        width: 22px
        margin: 2px 0

      &-image
        display: block
        left: 50%
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        width: 100%

        &.is-active
          opacity: 0

      span
        font-size: .8em
        margin-top: 5px

        @media screen and (min-width: 375px)
          font-size: .9em
  .swiper
    flex: 1

    &-button
      align-items: center
      display: flex
      flex-basis: 30px
      justify-content: center

      @media screen and (min-width: 1080px)
        display: none

      &.disabled
        opacity: 0
        pointer-events: none
</style>

<script>
import Vue from "vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import IconBase from "@/components/shared/IconBase.vue";
import { convertInternalLink } from "@/utils/convertInternalLink";
import APIs from "@/api/exhibition.js";

export default Vue.extend({
  components: {
    IconBase,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isOpen: true,
      options: [],
      currentItem: 0,
      slidesPerView: 0,
    };
  },
  async created() {
    this.apis = await new APIs(this);
    // 呼叫登入 API
    // 取得頁籤資訊
    await this.apis.getExhibitionTabInfo(this.getTabSuccess, this.getTabError);
  },
  computed: {
    swiper() {
      return this.$refs.refSwiper.$swiper;
    },
    swiperOption() {
      return {
        pagination: false,
        effect: "fade",
        spaceBetween: 0,
        slidesPerView: 4,
        breakpoints: {
          480: {
            slidesPerView: this.options.length > 5 ? 5 : this.options.length,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: this.options.length > 6 ? 6 : this.options.length,
          },
          1024: {
            slidesPerView: this.options.length > 8 ? 8 : this.options.length,
          },
          1080: {
            slidesPerView: this.options.length > 10 ? 10 : this.options.length,
          },
        },
      };
    },
  },
  methods: {
    createTabEvent(name) {
      const parameter = {
        event: "tab.click",
        data: {
          name: name,
        },
      };

      this.apis.createEvent(
        parameter,
        this.createEventSuccess,
        this.createEventError
      );
    },
    createEventSuccess(_response) {
      if (_response.status === 200) {
        console.log("成功紀錄頁籤點擊");
      }
    },
    createEventError(_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
    toggleNav() {
      this.isOpen = !this.isOpen;
    },
    clickedNext() {
      if (this.swiper !== null) {
        this.swiper.slideNext();
      }
    },
    clickedPrev() {
      if (this.swiper !== null) {
        this.swiper.slidePrev();
      }
    },
    onSlideChangeTransitionEnd: function (swiper) {
      this.currentItem = swiper.activeIndex;
      // this.slidesPerView = swiper.passedParams.breakpoints[swiper.currentBreakpoint]['slidesPerView']
    },
    // 取資料處理回傳正常的回應
    getTabSuccess(_response) {
      if (_response.status === 200) {
        this.options = _response.body;
        console.log("成功取得頁籤資訊");
        this.$root.isLogin = true;

        this.options.map((item) => {
          item.url = convertInternalLink(item.url);
        });
      }
    },
    // 取資料處理回傳異常的回應
    getTabError(_response) {
      console.log("未成功取得頁籤資訊", _response);
      
      if (_response.status === 401) {
        this.$root.getRefreshToken();
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
  },
});
</script>
