<template>
  <div
    class="lobby-table"
    v-if="data.display"
  >
    <div
      class="lobby-table-bg"
      :style="`background-image: url('${data.tableImage}')`"
    ></div>
    <div
      class="lobby-table-logo"
      :style="`background-color: ${data.tableColor}; background-image: url('${data.logoImage}')`"
    ></div>
  </div>
</template>

<style lang="sass" scoped>
  .lobby-table
    margin: auto
    max-width: 300px
    padding: 0 1vw
    position: relative

    @media screen and (min-width: 768px)
      left: 50%
      margin-top: 0
      max-width: none
      position: absolute
      top: 65%
      transform: translateX(-50%)
      width: 15vw

    &-bg,
    &-logo
      background-position: center
      background-repeat: no-repeat
      background-size: contain

    &-bg
      padding-bottom: calc(99.99% * 626 / 1020)

    &-logo
      bottom: 8.6%
      left: 9.4%
      position: absolute
      right: 9.4%
      top: 36.6%

      &:before
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) 5%)
        bottom: 0
        content: ''
        display: block
        left: 0
        position: absolute
        right: 0
        top: 0
</style>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    data: Object,
  },
});
</script>
