<template>
  <div
    class="embed-frame"
    :style="`
      max-width: ${maxWidth};
      margin: ${center ? '0 auto' : null};
    `"
  >
    <div
      class="embed-frame-content"
      :style="`padding-bottom: calc(100% * ${height} / ${width});`"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="sass" scoped>
.embed-frame
  position: relative

  &-content
    > *
      border: none
      left: 0
      position: absolute
      width: 100%
      height: 100%

      iframe
        height: 100%
</style>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    center: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "1000px",
    },
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 9,
    },
  },
});
</script>
