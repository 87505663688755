<template>
  <div></div>
</template>
<script>
import Vue from "vue";
import APIs from "@/api/account.js"
import { setStoredAccessToken, setStoredRefreshToken } from '@/utils/auth'

export default Vue.extend({
  data () {
    return {
      apis: false,
      access_code: '',
      path: '/'
      //- eererg
    };
  },
  async created () {
    this.apis = await new APIs(this);
    this.access_code = this.$route.params.id
    this.path = this.$route.query.path ? `/${this.$route.query.path}` : '/'

    this.apis.QuickAccess(this.access_code, this.QuickAccessSuccess, this.QuickAccessError)
  },
  methods: {
    // 快速登入處理回傳正常的回應
    QuickAccessSuccess (_response) {
      if (_response.status === 200) {
        console.log('快速登入成功')
        setStoredAccessToken(_response.body.accessToken)
        setStoredRefreshToken(_response.body.refreshToken)
        this.$router.push({ path: this.path });
      }
    },
    // 快速登入處理回傳異常的回應
    QuickAccessError (_response) {
      this.$root.useAlert("red", _response.body.msg);
      this.$router.push({ path: "/login" });
    },
  },
});
</script>
