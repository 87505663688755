<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    viewBox="0 0 30 30"
    :aria-labelledby="name" 
    role="presentation"
  >
    <title :id="name" lang="en">{{name}} icon</title>
    <g :fill="color">
      <icon-close v-if="name === 'icon-close'" />
      <icon-collect v-if="name === 'icon-collect'" />
      <icon-down v-if="name === 'icon-down'" />
      <icon-exhibition v-if="name === 'icon-exhibition'" />
      <icon-form v-if="name === 'icon-form'" />
      <icon-gift v-if="name === 'icon-gift'" />
      <icon-handout v-if="name === 'icon-handout'" />
      <icon-lecturer v-if="name === 'icon-lecturer'" />
      <icon-lobby v-if="name === 'icon-lobby'" />
      <icon-magnifier-minus v-if="name === 'icon-magnifier-minus'" />
      <icon-magnifier-plus v-if="name === 'icon-magnifier-plus'" />
      <icon-more-app v-if="name === 'icon-more-app'" />
      <icon-next v-if="name === 'icon-next'" />
      <icon-prev v-if="name === 'icon-prev'" />
      <icon-schedule v-if="name === 'icon-schedule'" />
      <icon-session v-if="name === 'icon-session'" />
      <icon-social v-if="name === 'icon-social'" />
    </g>
  </svg>
</template>

<script>
  import IconClose from '@/components/icons/IconClose.vue'
  import IconCollect from '@/components/icons/IconCollect.vue'
  import IconDown from '@/components/icons/IconDown.vue'
  import IconExhibition from '@/components/icons/IconExhibition.vue'
  import IconForm from '@/components/icons/IconForm.vue'
  import IconGift from '@/components/icons/IconGift.vue'
  import IconHandout from '@/components/icons/IconHandout.vue'
  import IconLecturer from '@/components/icons/IconLecturer.vue'
  import IconLobby from '@/components/icons/IconLobby.vue'
  import IconMagnifierMinus from '@/components/icons/IconMagnifierMinus.vue'
  import IconMagnifierPlus from '@/components/icons/IconMagnifierPlus.vue'
  import IconMoreApp from '@/components/icons/IconMoreApp.vue'
  import IconNext from '@/components/icons/IconNext.vue'
  import IconPrev from '@/components/icons/IconPrev.vue'
  import IconSchedule from '@/components/icons/IconSchedule.vue'
  import IconSession from '@/components/icons/IconSession.vue'
  import IconSocial from '@/components/icons/IconSocial.vue'

  export default {
    components: {
      IconClose,
      IconCollect,
      IconDown,
      IconExhibition,
      IconForm,
      IconGift,
      IconHandout,
      IconLecturer,
      IconLobby,
      IconMagnifierMinus,
      IconMagnifierPlus,
      IconMoreApp,
      IconNext,
      IconPrev,
      IconSchedule,
      IconSession,
      IconSocial,
    },
    props: {
      name: {
        type: String,
        default: 'lobby'
      },
      width: {
        type: [Number, String],
        default: 30
      },
      height: {
        type: [Number, String],
        default: 30
      },
      color: {
        type: String,
        default: 'currentColor'
      }
    }
  }
</script>

<style lang="sass" scoped>
  svg
    display: inline-block
    vertical-align: baseline
</style>
