<template>
	<div class="loader"><span><i :style="`border-color: ${color};`" /></span></div>
</template>

<style lang="sass" scoped>
.loader
	animation: loader-1-1 4.8s linear infinite
	height: 32px
	width: 32px

	span
		animation: loader-1-2 1.2s linear infinite
		bottom: 0
		clip: rect(0, 32px, 32px, 16px)
		display: block
		height: 32px
		left: 0
		margin: auto
		position: absolute
		right: 0
		top: 0
		width: 32px

		i
			animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite
			border-radius: 50%
			border-style: solid
			border-width: 3px
			bottom: 0
			clip: rect(0, 32px, 32px, 16px)
			content: ""
			height: 32px
			left: 0
			margin: auto
			position: absolute
			right: 0
			top: 0
			width: 32px

@keyframes loader-1-1
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)

@keyframes loader-1-2
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(220deg)

@keyframes loader-1-3
	0%
		transform: rotate(-140deg)
	50%
		transform: rotate(-160deg)
	100%
		transform: rotate(140deg)
</style>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
	props: {
		color: String
	}
})
</script>
