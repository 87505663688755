import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage)

const accessToken = '__a'
const refreshToken = '__r'
const username = '__u'

export const getStoredAccessToken = () => {
  return Vue.localStorage.get(accessToken)
}

export const setStoredAccessToken = (token) => {
  return Vue.localStorage.set(accessToken, token)
}

export const removeStoredAccessToken = () => {
  return Vue.localStorage.remove(accessToken)
}

export const getStoredRefreshToken = () => {
  return Vue.localStorage.get(refreshToken)
}

export const setStoredRefreshToken = (token) => {
  return Vue.localStorage.set(refreshToken, token)
}

export const removeStoredRefreshToken = () => {
  return Vue.localStorage.remove(refreshToken)
}

export const getStoredLoginUsername = () => {
  return Vue.localStorage.get(username)
}

export const setStoredLoginUsername = (_username) => {
  return Vue.localStorage.set(username, _username)
}

export const removeStoredLoginUsername = () => {
  return Vue.localStorage.remove(username)
}
