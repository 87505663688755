<template>
  <div class="area">
    <!-- 背景 -->
    <div
      v-if="area_data.background"
      class="background"
      :style="`background-image: url('${area_data.background}')`"
    >
      <!-- 大尺吋螢幕 攤位區塊 -->
      <area-large-content
        v-if="area_data.area"
        :data="area_data.area"
        :nav="area_data.nav"
      />
    </div>

    <top-banner :src="area_data.banner" />

    <!-- 小尺吋螢幕 攤位區塊 -->
    <area-small-content
      v-if="area_data.area"
      :data="area_data.area"
    />
  </div>
</template>

<style lang="sass" scoped>
.area
  position: relative
  min-height: 100%
  background: #BBE1FE

  @media screen and (min-width: 768px)
    height: 100%

  .background
    position: absolute
    left: 0
    top: 0
    background-repeat: no-repeat
    background-position: top center
    background-size: cover
    width: 100%
    height: 100vh
    display: none

    @media screen and (min-width: 768px)
      display: block
      height: 100%

  // 設定 Top Banner 在此頁的相對定位
  .top-banner
    @media screen and (min-width: 768px)
      position: relative
      top: 2vw
      z-index: 1
</style>

<script>
import Vue from "vue";
import AreaLargeContent from "@/components/pages/area/AreaLargeContent.vue";
import AreaSmallContent from "@/components/pages/area/AreaSmallContent.vue";
import TopBanner from '@/components/shared/TopBanner.vue'
import APIs from "@/api/area.js";
export default Vue.extend({
  components: {
    TopBanner,
    AreaLargeContent,
    AreaSmallContent,
  },
  data: function () {
    return {
      apis: false,
      area_data: {},
      // area_data: {
      //   "nav":{
      //     "arrow_up":"https://demo.exhibition.ictsage.com/storage/v2/area/arrow-up.png",
      //     "arrow_down":"https://demo.exhibition.ictsage.com/storage/v2/area/arrow-down.png",
      //     "pagination":"https://demo.exhibition.ictsage.com/storage/v2/area/pagination.png"},
      //     "area":[
      //       {"url":"https://www.storm.mg/article/3997037",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-10.png",
      //     "name":"測試攤位",
      //     "text":"立委黃國書因為曾任國民黨線民被發現後，近期被民進黨新潮流除名。據悉，由於去年起調查局陸續移轉當年監控相關檔案到促轉會，所以自認當年曾被監控的綠營政治人物，也陸續查看相關資料，就在某名新系要角去年看完個人資料後，驚覺「怪怪的」，後來新系內部人士陸續查看檔案，比對後，發現黃國書確實是線民，也因此導致黃國書約在今年8月中確定被除名。",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-01.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/LOGO-17.png",
      //     "name":"測試測試",
      //     "text":"測試測試測試測試測試測試測試測試測試測試測試測試",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-07.png"},
      //     {"url":"",
      //     "logo":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/ictsage.png",
      //     "name":"123",
      //     "text":"",
      //     "stall_img":"https://filestore.ictsage.com/exhibition/prod/l4fgdgrae8/booth-06.png"}
      //     ],"banner":"https://demo.exhibition.ictsage.com/storage/v2/area/banner.png",
      //     "background":"https://demo.exhibition.ictsage.com/storage/v2/area/background.png"},
    };
  },
  async created () {
    this.apis = await new APIs(this)
    // 呼叫登入 API
    // 取得展覽區資訊
    await this.apis.getAreaInfo(this.getAreaSuccess, this.getAreaError)
  },
  methods: {
    // 取資料處理回傳正常的回應
    getAreaSuccess (_response) {
      if (_response.status === 200) {
        this.area_data = _response.body
        console.log('成功取得展覽區資訊');
      }
    },
    // 取資料登入處理回傳異常的回應
    getAreaError (_response) {
      if (_response.status === 401) {
        this.$root.getRefreshToken()
      } else {
        this.$root.useAlert("red", _response.body.msg);
      }
    },
  },
});
</script>
