import { render, staticRenderFns } from "./Venue.vue?vue&type=template&id=9dc99f52&scoped=true&"
import script from "./Venue.vue?vue&type=script&lang=js&"
export * from "./Venue.vue?vue&type=script&lang=js&"
import style0 from "./Venue.vue?vue&type=style&index=0&id=9dc99f52&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dc99f52",
  null
  
)

export default component.exports