<template>
  <div class="lecture-middle">

    <!-- 左海報 -->
    <div
      v-if="data.poster_left"
      class="post-lg image-background"
      :style="`background-image: url('${data.poster_left}')`"
    ></div>

    <!-- 中間區塊 -->
    <div class="screen">
      <lecture-screen
        v-if="data.screen"
        :data="data.screen"
      />
    </div>

    <!-- 右海報 -->
    <div
      v-if="data.poster_right"
      class="post-lg image-background"
      :style="`background-image: url('${data.poster_right}')`"
    ></div>

    <div class="clearfix"></div>

    <!-- 小螢幕時才顯示的海報畫面  -->
    <!-- 左海報 -->
    <div
      v-if="data.poster_left"
      class="post-sm-left image-background"
      :style="`background-image: url('${data.poster_left}')`"
    ></div>
    <!-- 右海報 -->
    <div
      v-if="data.poster_right"
      class="post-sm-right image-background"
      :style="`background-image: url('${data.poster_right}')`"
    ></div>

    <div class="clearfix"></div>

  </div>
</template>

<style lang="sass" scoped>
.lecture-middle
  position: relative
  width: 100%
  height: auto
  min-width: none
  bottom: .3rem
  left: 0
  min-width: none
  padding-bottom: 31rem
  @media (min-width: 800px)
    bottom: 0.2rem
  @media (min-width: 835px)
    bottom: 0rem
  @media (min-width: 768px)
    width: calc( 100% )
    bottom: -.5rem
    padding-bottom: 0
  .post-lg
    display: none
    height: 10rem
    width: 25%
    float: left
    position: relative
    min-height: inherit
    padding-bottom: calc(25% * 9 / 8 )
    @media (min-width: 768px)
      display: block
    img
      object-fit: cover
  .screen
    width: 100%
    float: left
    @media (min-width: 768px)
      width: 50%
    @media (min-width: 1300px)
      min-width: none
  .post-sm-left
    display: block
    width: 50%
    float: left
    padding-bottom: calc(50% * 9 / 8 )
    @media (min-width: 768px)
      display: none
  .post-sm-right
    display: block
    width: 50%
    float: right
    padding-bottom: calc(50% * 9 / 8 )
    @media (min-width: 768px)
      display: none

  .clearfix
    clear: both

  .image-background
    background-position: top
    background-repeat: no-repeat
    background-size: contain
    bottom: 0
    left: 0
    right: 0
    top: 0

  .opacity-0
    opacity: 0
</style>

<script lang="ts">
import Vue from "vue";
import LectureScreen from "@/components/pages/lecture/LectureScreen.vue";
export default Vue.extend({
  props: {
    data: Object,
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 9,
    },
  },
  components: {
    LectureScreen,
  },
});
</script>
