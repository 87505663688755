<template>
  <div class="lobby-title">{{ data }}</div>
</template>

<style lang="sass" scoped>
  .lobby-title
    color: #00317F
    font-size: 1.25em
    font-weight: 500
    overflow: hidden
    padding: .25em 0
    text-align: center
    text-overflow: ellipsis
    white-space: nowrap

    @media screen and (min-width: 768px)
      font-size: 2.3vw
      left: 50%
      position: absolute
      top: 1%
      transform: translateX(-50%)
      z-index: 1
</style>

<script>
export default {
  props: {
    data: String
  }
}
</script>
