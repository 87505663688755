<template>
  <div
    class="lobby-session"
    v-if="data.display"
  >
    <div class="lobby-session-entrance">
      <a :href="convertInternalLink(data.url)">
        <div
          class="lobby-session-entrance-bg"
          :style="`background-image: url('${data.entrance}')`"
        />
      </a>
    </div>
    <div
      class="lobby-session-deco"
      :style="`background-image: url('${data.deco}')`"
    />
  </div>
</template>

<style lang="sass" scoped>
.lobby-session
  width: 100%

  @media screen and (min-width: 768px)
    position: absolute
    right: 18vw
    top: 59%
    width: 19vw

  &-entrance
    margin: auto

    @media screen and (min-width: 768px)
      width: 60%

  &-entrance-bg,
  &-deco
    background-position: center
    background-repeat: no-repeat
    background-size: contain

  &-entrance-bg
    padding-bottom: calc(99.99% / 1024 * 645)

  &-deco
    bottom: -20%
    display: none
    left: 0
    padding-bottom: calc(99.99% / 512 * 182)
    pointer-events: none
    position: absolute
    width: 100%

    @media screen and (min-width: 768px)
      display: block
</style>

<script>
import Vue from "vue";
import { convertInternalLink } from '@/utils/convertInternalLink'

export default Vue.extend({
  props: {
    data: Object,
  },
  methods: {
    convertInternalLink: function (value) {
      return convertInternalLink(value)
    }
  }
});
</script>
