<template>
  <fragment>
    <div class="lecture-screen">
      <embed-frame maxWidth="1800px">
        <video
          v-if="data.type === 'video'"
          :src="data.url"
          :poster="data.poster"
          autoplay
          controls
          muted
          playsinline
        />
        <v-img
          v-else-if="data.type === 'image'"
          :src="data.poster"
        />
        <iframe
          v-else-if="data.type === 'iframe'"
          :src="data.url"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </embed-frame>
    </div>
  </fragment>
</template>

<style lang="sass" scoped>
.lecture
  &-screen
    border: 1px solid #FFFFFF
</style>

<script lang="ts">
import Vue from "vue";
import { Fragment } from "vue-fragment";
import EmbedFrame from "@/components/shared/EmbedFrame.vue";

export default Vue.extend({
  props: {
    data: Object,
  },
  components: {
    Fragment,
    EmbedFrame,
  },
  mounted() {
    document.querySelector('.vid').play();
  }
});
</script>
