<template>
  <div class="lecture-top">

    <div class="spotlight">
      <!-- 左方聚光燈 -->
      <div
        v-if="top_data.spotlight_left_url"
        class="spotligh-left image-background"
        :style="`background-image: url('${top_data.spotlight_left_url}')`"
      ></div>

      <!-- 右方聚光燈 -->
      <div
        v-if="top_data.spotlight_right_url"
        class="spotligh-right image-background"
        :style="`background-image: url('${top_data.spotlight_right_url}')`"
      ></div>
    </div>

    <!-- 跑馬燈 -->
    <div class="marquee">
      <div
        v-if="top_data.marquee_top_url"
        class="marquee-top-img image-background"
        :style="`background-image: url('${top_data.marquee.marquee_top_url}')`"
      ></div>
      <div
        class="marquee-bg"
        :style="`background-image: url('${top_data.marquee.marquee_url}')`"
      >
        <div class="marquee-content">
          <div
            class="marquee-msg"
            id="marquee-msg"
          >
            <a
              v-if="top_data.marquee.marquee_text[now_msg_num].url"
              class="marquee-text"
              :href="top_data.marquee.marquee_text[now_msg_num].url"
              :target="top_data.marquee.marquee_text[now_msg_num].external === true ? '_blank' : null"
            >
              <span> {{now_marquee_msg.value}} </span>
            </a>
            <span v-else> {{now_marquee_msg.value}} </span>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="sass" scoped>
.lecture-top
  position: relative
  width: 100%
  padding: 0
  @media (min-width: 768px)
    padding: .6rem 0 0 0
  .spotlight
    display: none
    position: absolute
    top: 0
    left: calc((1524px - 100%) /2 * -1)
    width: 100%
    min-width: 1524px
    height: 24rem
    @media (min-width: 768px)
      display: block
    @media (min-width: 1524px)
      left: 0

    .spotligh-left
      position: absolute
      left: 18%
      width: 22rem
      top: -.1rem
      z-index: 2
    .spotligh-right
      position: absolute
      right: 18%
      width: 22rem
      top: -.1rem
      z-index: 2

  .marquee
    position: relative
    margin: 0 auto
    z-index: 4
    width: 100%
    min-width: none
    @media (min-width: 768px)
      width: calc(50% + 1rem)
      min-width: 768px
    .marquee-top-img
      display: none
      position: absolute
      top: -0.2rem
      left: -1%
      width: 102%
      max-width: none
      background-size: contain
      @media (min-width: 900px)
        display: block
    .marquee-bg
      background-position: center
      background-size: cover
      width: 102%
      position: relative
      right: 1%
      padding: 0.3rem 1.38% 0.7rem 1.38%
      font-size: 1.3rem
      font-weight: 400
      line-height: 3rem
      height: 4rem
      color: #FFFFFF
      text-shadow: 0px 0px 10px rgb(17 255 212 / 25%), 0px 0px 10px rgb(0 255 255 / 50%)
      -webkit-text-stroke: 0.5px #99EAE5
      text-align: left
      @media (min-width: 768px)
        width: 100%
        right: 0
        font-size: 1.5rem
      .marquee-content
        width: 100%
        height: 100%
        position: relative
        overflow: hidden
        .marquee-msg
          height: 100%
          width: fit-content
          position: absolute
          top: 0
          left: 101%

    a.marquee-text
      text-decoration: none
      color: #fff

  .image-background
    background-position: top
    background-repeat: no-repeat
    background-size: cover
    bottom: 0
    right: 0
    top: 0
    position: absolute
</style>

<script>
import Vue from "vue";
import { convertInternalLink } from '@/utils/convertInternalLink'
export default Vue.extend({
  props: {
    top_data: Object,
    now_marquee_msg: String,
    now_msg_num: Number
  },
  mounted () {
    this.top_data.marquee.marquee_text.map(item => {
      item.url = convertInternalLink(item.url)
    })
    this.getMarqueeWidith();
  },
  data () {
    return {
      msg_width: 0
    };
  },
  watch: {
    now_marquee_msg: {
      handler: function () { 
        setTimeout(() => {
          this.getMarqueeWidith()
        }, 100);
      },
      deep: true
    }
  },
  methods: {
    getMarqueeWidith () {
      var elmnt;
      elmnt = document.getElementById("marquee-msg");
      this.msg_width = elmnt.offsetWidth;
      console.log("目前跑馬燈文字寬度" + this.msg_width);

      elmnt.animate([
        { left: 'calc( 100% + ' + this.msg_width + 'px)' },
        { left: '-' + this.msg_width + 'px' }
      ], {
        duration: 15000
      });
      setTimeout(() => {
        this.$emit('changeMsg')
      }, 15000);

    },
  },
});
</script>
