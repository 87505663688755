<template>
  <fragment>
    <div class="forum-screen">
      <embed-frame>
        <video
          class="vid"
          v-if="data.type === 'video'"
          :src="data.url"
          :poster="data.poster"
          controls
          loop
          autoplay
          muted
          playsinline
        />
        <div v-if="data.type === 'image'">
          <a
            v-if="data.url"
            :href="convertInternalLink(data.url)"
            :target="data.external === true ? '_blank' : null"
          >
            <v-img :src="data.poster" />
          </a>
          <v-img v-else :src="data.poster" />
        </div>
        <iframe
          v-else-if="data.type === 'iframe'"
          :src="data.url"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </embed-frame>
    </div>
    <div class="forum-cta">
      <!-- 測試站內視窗顯示站外連結 -->
      <!-- <template v-if="data.cta.external">
        <a @click.stop="externalModal = true">
          <embed-frame width="600" height="168">
            <v-img
              :src="data.cta.hover ? data.cta.imageActive : data.cta.image"
              :alt="data.cta.name"
              @mouseover="data.cta.hover = true"
              @mouseleave="data.cta.hover = false"
            />
          </embed-frame>
        </a>
        <ExternalModal
          :url="data.cta.url"
          :dialog.sync="externalModal"
        ></ExternalModal>
      </template> -->
      <a
        :href="convertInternalLink(data.cta.url)"
        :target="data.cta.external === true ? '_blank' : null"
      >
        <!-- <embed-frame width="600" height="168"> -->
          <!-- <v-img
            :src="data.cta.hover ? data.cta.imageActive : data.cta.image"
            :alt="data.cta.name"
            @mouseover="data.cta.hover = true"
            @mouseleave="data.cta.hover = false"
          /> -->
        <!-- </embed-frame> -->
        <div 
          @mouseover="data.cta.hover = true"
          @mouseleave="data.cta.hover = false"
          class="image-square"
          style="padding-top: 29%"
          >
          <img class="z-index-1" :class="data.cta.hover ? 'opacity-0' : ''" :src="data.cta.image" alt="">
          <img :class="data.cta.hover ? '' : 'opacity-0'" :src="data.cta.imageActive" alt="">
        </div>
      </a>
    </div>
  </fragment>
</template>

<style lang="sass" scoped>
.forum
  &-screen
    background: linear-gradient(270deg, #000000 -1.25%, #7A7A7A 35.66%, #7A7A7A 48.32%, #000000 100%)

    @media screen and (min-width: 768px)
      border-radius: 5px
      left: 50%
      padding: 1%
      position: absolute
      top: 11%
      transform: translateX(-50%)
      width: 37%

  &-cta
    margin: 1em auto
    max-width: 200px
    width: 70%

    @media screen and (min-width: 768px)
      left: 50%
      margin: 0
      max-width: none
      position: absolute
      top: 55%
      transform: translateX(-50%)
      width: 12%

    a
      display: block
</style>

<script>
import Vue from "vue";
import { Fragment } from "vue-fragment";
import EmbedFrame from "@/components/shared/EmbedFrame.vue";
import { convertInternalLink } from "@/utils/convertInternalLink";
// import ExternalModal from "@/components/modals/External.vue";

export default Vue.extend({
  props: {
    data: Object,
  },
  components: {
    Fragment,
    EmbedFrame,
    // ExternalModal,
  },
  methods: {
    convertInternalLink: function (value) {
      return convertInternalLink(value);
    },
  },
});
</script>

<style scoped lang="scss">
.opacity-0{
  opacity: 0;
}
.z-index-1{
  z-index: 1;
}

.image-square {
  width: 100%;
  padding-top: 100%;
  position: relative;
  display: block;
  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
</style>
